import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './Loading.module.scss';

interface LoadingProps {
  isLoading: boolean
}

// TODO Centered vertically & horizontally
export const Loading: React.FC<LoadingProps> = ({ isLoading, children }) => {
  return isLoading ? (
      <div className={styles.Wrapper}>
        <CircularProgress className={styles.Loading} />
      </div>
    ) :
    <>{children}</>;
};
