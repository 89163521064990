import React, { useState } from 'react';
import { History } from 'history';
import { Redirect } from 'react-router';

type Path = History.Path;

interface RedirectUserProps {
  to: Path;
}

export const RedirectUser: React.FC<RedirectUserProps> = ({ to, children }) => {
  const [currentUser] = useState(false);
  if (currentUser) {
    return <Redirect to={to} />;
  }
  return <>{children}</>;
};
