import React from 'react';
import { useLayoutManager, LayoutManagerContext } from './layout-manager.hook';

export const LayoutProvider: React.FC = ({ children }) => {
  const manager = useLayoutManager();
  return (
    <LayoutManagerContext.Provider value={manager}>
      {children}
    </LayoutManagerContext.Provider>
  );
};

export const withLayoutProvider = () =>
  <Props, >(Component: React.ComponentType<Props>): React.FC<Props> =>
    React.memo(function WithLayoutProvider(props) {
      return (
        <LayoutProvider>
          <Component {...props} />
        </LayoutProvider>
      );
    });
