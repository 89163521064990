import React, { ReactElement } from 'react';
import { Tab, Tabs, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const AppTab = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
  },
}))(Tab);

export const AppNav = (): ReactElement => {
  const [value, setValue] = React.useState(0);

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }

  return (
    <Tabs
      value={value}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      aria-label="Navigate between applications"
      centered
    >
      <AppTab label="Todos" />
      <AppTab label="Notes" disabled />
      <AppTab label="Messaging" disabled />
      <AppTab label="Calendar" disabled />
    </Tabs>
  );
};
