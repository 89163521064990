import * as yup from 'yup';

export interface ActivationFormModel {
  username: string;
  code: string;
}
export const activationSchema = yup.object().shape({
  code: yup.string()
    .required('You have to provide activation code'),
});
