import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useFirebase } from '../firebase';
import { User } from '@firebase/auth-types';

export enum AuthStatus {
  UNSET,
  LOADING,
  ERROR,
  SIGNED_IN,
  SIGNED_OUT,
}

interface AuthState {
  status: AuthStatus;
  token?: string;
  user?: User;
}

interface AuthManager extends AuthState {
  startedLoading(): void;
  signedIn(token: string, user: User): void;
  signedOut(): void;
  errored(error?: Error): void;
}

export const initialState: AuthState = {
  status: AuthStatus.LOADING,
};

const { reducer, actions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loading: (state): AuthState => ({ ...state, status: AuthStatus.LOADING }),
    errored: ((): AuthState => ({ status: AuthStatus.ERROR })),
    signedOut: (): AuthState => ({ status: AuthStatus.SIGNED_OUT }),
    signedIn: (_, { payload }: PayloadAction<Omit<AuthState, 'status'>>): AuthState => ({
      status: AuthStatus.SIGNED_IN,
      ...payload,
    }),
  },
});

export const AuthContext = createContext<AuthManager | undefined>(undefined);

export const useAuthManager = (): AuthManager => {
  const authManager = useContext(AuthContext);
  if (!authManager) {
    throw new Error(`The 'useAuth' can only be used within 'AuthProvider' context.`);
  }
  return authManager;
};

export const useSpawnAuthManager = (): AuthManager => {
  const { auth } = useFirebase();
  const defaultState: AuthState = {
    ...initialState,
    user: auth.currentUser || undefined,
  };
  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(function initializeAuth() {
    const user = auth.currentUser;
    if (!user) {
      return;
    }
    user
      .getIdToken()
      .then(token => dispatch(actions.signedIn({ token, user })))
      .catch(error => {
        dispatch(actions.errored());
        console.error('Failed to initialize authentication state.');
        console.error(error);
      });
  }, [auth, dispatch]);

  const signedIn = useCallback((token: string, user: User) => {
    dispatch(actions.signedIn({ token, user }));
  }, []);

  const signedOut = useCallback(() => {
    dispatch(actions.signedOut());
  }, []);

  const errored = useCallback((_error?: Error) => {
    dispatch(actions.errored());
  }, []);

  const startedLoading = useCallback((_error?: Error) => {
    dispatch(actions.loading());
  }, []);

  return useMemo(() => ({
    ...state,
    signedIn,
    signedOut,
    errored,
    startedLoading,
  }), [state, signedIn, signedOut, errored, startedLoading]);
};
