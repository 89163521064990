import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { projectFragment, ProjectModel } from '../../models';

export const useCachedProject = () => {
  const client = useApolloClient();
  return useCallback((id: string): ProjectModel | null => {
    return client.readFragment<ProjectModel>({
      id: `project:${id}`,
      fragment: projectFragment,
    });
  }, [client]);
};
