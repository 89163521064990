import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { NotificationType, projectFragment, ProjectModel } from '../../models';
import { UpdateProjectInputModel } from '../../models/projects';
import { useNotifications } from '../../core/message-bar';

export const updateProjectMutation = gql`
  mutation UpdateProject(
    $id: uuid!,
    $input: project_set_input!
  ) {
    project: update_project_by_pk(
      pk_columns: { id: $id },
      _set: $input,
    ) {
      ...ProjectModel
    }
  }
  
  ${projectFragment}
`;

export interface UpdateProjectMutationResult {
  project: ProjectModel;
}

export const useUpdateProject = () => {
  const [updateTodo, result] = useMutation(updateProjectMutation);
  const { display } = useNotifications();

  const onFailure = useCallback((error) => {
    console.error(error);
    display({
      type: NotificationType.ERROR,
      message: 'Failed to update a project',
    });
  }, [display]);

  const performMutation = useCallback(({ id, ...input }: UpdateProjectInputModel) =>
    updateTodo({ variables: { input, id } })
      .catch(onFailure), [updateTodo, onFailure]);

  if (result.error) {
    onFailure(result.error);
  }

  return {
    updateProject: performMutation,
    ...result,
  };
}
