import { TodoModel } from './todo.model';
import { ProjectModel } from '..';

export enum DnDMoveType {
  BEFORE = 'before',
  AFTER = 'after',
  CENTRE = 'centre',
}

export interface TodoDropModel {
  type: DnDMoveType;
  todoId: TodoModel['id'];
  parentId: TodoModel['id'] | ProjectModel['id'];
  isRoot: boolean;
  index: number;
  disabledIds?: string[];
}
