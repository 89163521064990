import React, { ComponentType, useCallback, useRef } from 'react';
import { FlatCard } from '../FlatCard';
import { Button, CardActions, CardContent, SvgIcon, Typography } from '@material-ui/core';
import VerifiedUserTwoTone from '@material-ui/icons/VerifiedUserTwoTone';
import { AdapterLink } from '../AdapterLink';
import { Paths } from '../../paths';
import { FormikProps } from 'formik';
import { useStyles } from './card-styles';
import { LinkAction, LinkActionDef } from './LinkAction';
import { FormikRef } from '../../models/types';

interface FormComponentProps<Model> {
  ref: FormikRef<Model>;
  onSubmit(values: Model): Promise<void>;
}

interface AuthCardProps<Model extends object = any> {
  onSubmit(values: Model): Promise<void>;
  alternativeAction?: {
    targetPath: Paths;
    actionText: string;
  }
  linkAction?: LinkActionDef;
  formComponent: ComponentType<FormComponentProps<Model>>;
  iconComponent?: typeof SvgIcon;
  title: string;
  submitText: string;
  info?: string;
}

export const AuthCard = <Model extends object>({
  onSubmit,
  formComponent: FormComponent,
  iconComponent: Icon = VerifiedUserTwoTone,
  title,
  alternativeAction,
  linkAction,
  submitText,
  info,
}: AuthCardProps<Model>) => {
  const formRef = useRef<FormikProps<Model> | undefined>();
  const classes = useStyles();
  const handleSubmitForm = useCallback((e) => {
    if (formRef.current !== undefined) {
      formRef.current.handleSubmit(e);
    }
  }, [formRef]);
  const isSubmitting = formRef.current && formRef.current.isSubmitting;

  if (!FormComponent) {
    return null;
  }
  return (
    <FlatCard className={classes.card}>
      <Typography variant="h5" component="h2" color="textPrimary" gutterBottom align="center">
        <Icon color="secondary" style={{ fontSize: 60 }} /><br />
        {title}
      </Typography>
      { info && <Typography variant="caption" className={classes.info}>{info}</Typography>}
      <CardContent>
        <FormComponent ref={formRef} onSubmit={onSubmit} />
        <LinkAction linkAction={linkAction} />
      </CardContent>
      <CardActions className={classes.cardFooter} disableSpacing>
        {alternativeAction &&
        <Button color="primary" variant="outlined" component={AdapterLink} to={alternativeAction.targetPath}>
          {alternativeAction.actionText}
        </Button>
        }
        <Button
          color="primary"
          variant="contained"
          className={classes.actionBtn}
          disabled={isSubmitting}
          onClick={handleSubmitForm}>
          {submitText}
        </Button>
      </CardActions>
    </FlatCard>
  );
};
