import { createMuiTheme } from '@material-ui/core';

export const lightTheme = createMuiTheme({
  palette: {
    primary: {
      100: '#c8f5fa',
      200: '#73ccd7',
      // light: '#3dbecc',
      main: '#0097a7',
      // dark: '#006773',
    },
    secondary: {
      100: '#ffe7b2',
      light: '#ffdd63',
      main: '#edc73b',
      dark: '#bf9843',
    },
    background: {
      default: 'white',
    }
  },
  typography: {
    fontFamily: '"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", ' +
      '"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    button: {
      textTransform: 'none',
    },
  },
});
