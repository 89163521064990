import React, { forwardRef } from 'react'
import { Field, Form, FormikProps } from 'formik';
import { ForgotPasswordFormModel, forgotPasswordSchema } from 'models';
import { TextField } from '../../Forms';
import { FormikRef } from '../../../models/types';
import { FormikWithRef } from '../../FormikWithRef';

interface ForgotPasswordFormProps {
  onSubmit(values: ForgotPasswordFormModel): Promise<void>;
}

const initialValues: ForgotPasswordFormModel = {
  email: '',
};

const ForgotPasswordFormBody: React.FC<ForgotPasswordFormProps & FormikProps<ForgotPasswordFormModel>> = ({ submitForm }) => (
  <Form onKeyDown={(e) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }}>
    <Field type="email" name="email" component={TextField} autoFocus />
  </Form>
);

export const ForgotPasswordForm = forwardRef((
  { onSubmit }: ForgotPasswordFormProps,
  ref: FormikRef<ForgotPasswordFormModel>,
) => (
  <FormikWithRef
    formRef={ref}
    initialValues={initialValues}
    validationSchema={forgotPasswordSchema}
    validateOnBlur
    onSubmit={onSubmit}
    component={ForgotPasswordFormBody}
  />
));
ForgotPasswordForm.displayName = 'ForgotPasswordForm';
