import * as yup from 'yup';

export interface ResetPasswordFormModel {
  username: string;
  code: string;
  password: string;
  confirmPassword: string;
}

export const resetPasswordSchema = yup.object().shape({
  code: yup.string()
    .required('You have to provide activation code'),
  password: yup.string()
    .min(8, 'Password must have at least 8 characters')
    .required('This field is required'),
  confirmPassword: yup.string()
    .min(8, 'Password must have at least 8 characters')
    .required('This field is required')
    .oneOf([yup.ref('password'), ''], 'Passwords must match'),
});
