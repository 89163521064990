import React from 'react';
import MuiSelect, { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import { useField } from 'formik';
import { InputLabel } from '@material-ui/core';

interface SelectProps extends MuiSelectProps {
  name: string;
  label?: string;
  autocomplete?: boolean;
}

export const Select: React.FC<SelectProps> = React.memo(function Select(props) {
  const { name, label, autocomplete = false, children, className } = props;
  const [field, {touched, error}] = useField(name);
  const labelId = `input-select-${name}_label`;
  return (
    <>
      { label && <InputLabel id={labelId}>{label}</InputLabel> }
      <MuiSelect
        labelId={label && labelId}
        id={`${name}-select`}
        error={touched && !!error}
        autoComplete={autocomplete ? name : 'off'}
        margin="dense"
        color="primary"
        fullWidth
        className={className}
        {...field}
      >
        {children}
      </MuiSelect>
    </>
  );
});
