import { gql, useMutation } from '@apollo/client';
import { todoFragment, TodoModel } from '../../models/todos';
import { useCallback } from 'react';
import { NotificationType } from '../../models';
import { UpdateTodoInputModel } from '../../models/todos/update-todo-input.model';
import { useNotifications } from '../../core/message-bar';

export const updateTodoMutation = gql`
  mutation UpdateTodo($id: uuid!, $input: todo_set_input!) {
    todo: update_todo_by_pk(pk_columns: {id: $id}, _set: $input) {
      ...TodoModel
    }
  }
  
  ${todoFragment}
`;

export interface UpdateTodoMutationResult {
  todo: TodoModel;
}

export const useUpdateTodo = () => {
  const [updateTodo, result] = useMutation(updateTodoMutation);
  const { display } = useNotifications();

  const onFailure = useCallback((error) => {
    console.error(error);
    display({
      type: NotificationType.ERROR,
      message: 'Failed to update a todo',
    });
  }, [display]);

  const performMutation = useCallback(({ id, ...input }: UpdateTodoInputModel) =>
    updateTodo({ variables: { input, id } })
      .catch(onFailure), [updateTodo, onFailure]);

  if (result.error) {
    onFailure(result.error);
  }

  return {
    updateTodo: performMutation,
    ...result,
  };
}
