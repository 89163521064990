import React from 'react';
import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@material-ui/lab';
import { Divider, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export interface ToggleOption {
  value: string;
  label: string;
}

export interface ToggleProps {
  options: ToggleOption[];
  value: string;
  onChange: ToggleButtonGroupProps['onChange'];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  grouped: {
    margin: theme.spacing(0),
    border: 'none',
    padding: theme.spacing(0.5, 1.5),
  },
  divider: {
    padding: 0,
    marginLeft: '0 !important',
    width: 0,
    margin: theme.spacing(1, 0),
    backgroundColor: theme.palette.primary.main,
    border: 0,
  },
  selected: {
    backgroundColor: 'inherit !important',
    color: `${theme.palette.primary.main} !important`,
  },
}));

export const Toggle: React.FC<ToggleProps> = ({ options, value, onChange }) => {
  const classes = useStyles();
  return (
    <ToggleButtonGroup
      size="small"
      value={value}
      onChange={onChange}
      classes={{ root: classes.root, grouped: classes.grouped }}
      exclusive
    >
      {options.map(({ value, label }, index) => [
        <ToggleButton value={value} key={value} classes={{ selected: classes.selected }}>{label}</ToggleButton>,
        index < options.length - 1 &&
        <Divider flexItem orientation="vertical" className={classes.divider} />,
      ])}
    </ToggleButtonGroup>
  );
};
