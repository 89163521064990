import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { DnDMoveType, TodoModel } from '../../models/todos';
import { useTodoDrop } from '../../hooks/todos';
import { ProjectModel } from '../../models';

interface TodoEdgeProps {
  type: DnDMoveType.BEFORE | DnDMoveType.AFTER;
  todo: TodoModel;
  parentId: ProjectModel['id'] | TodoModel['id'];
  disabledIds: Array<TodoModel['id']>;
  isRoot: boolean;
  index: number;
}

interface CollectedProps {
  isOver: boolean;
  canDrop: boolean;
}

type AllProps = TodoEdgeProps & CollectedProps;

const useStyles = makeStyles((theme: Theme) => ({
  edge: {
    height: ({ isOver, canDrop }: AllProps) => isOver && canDrop ? theme.spacing(4) : theme.spacing(1),
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(-0.5),
  },
}));

export const TodoEdge: React.FC<TodoEdgeProps> = (props) => {
  const { type, todo, parentId, isRoot, index, disabledIds } = props;
  const [collectedProps, dropRef] = useTodoDrop({
    type,
    todoId: todo.id,
    parentId,
    isRoot,
    index,
    disabledIds,
  });
  const classes = useStyles({ ...props, ...collectedProps });
  return <div className={classes.edge} ref={dropRef} />;
};
