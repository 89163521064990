export enum Paths {
  HOMEPAGE = '/',
  LOGIN = '/login',
  REGISTER = '/register',
  ACTIVATE_ACCOUNT = '/activate/:username',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password/:username',
  TODOS = '/todos',
  CHANGE_PASSWORD = '/change-password',
}
