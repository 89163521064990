import React, { useCallback } from 'react';
import { Checkbox, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useBatchDone } from '../../hooks/todos';
import { TodoModel, todosDFS } from '../../models/todos';

interface TodoCheckboxProps {
  todo: TodoModel;
  todos: Record<TodoModel['id'], TodoModel>;
}

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    padding: theme.spacing(0.5, 1),
    gridColumn: 2,
  },
}));

export const TodoCheckbox: React.FC<TodoCheckboxProps> = React.memo(
  function TodoCheckbox({ todo, todos }) {
    const classes = useStyles();
    const { batchTodosDone } = useBatchDone();
    const toggle = useCallback(() => {
      const ids = todosDFS([todo.id], todos);
      return batchTodosDone(todo.projectId, { ids, isDone: !todo.isDone });
    }, [todo.projectId, todo.id, todo.isDone, batchTodosDone, todos]);

    return (
      <Checkbox
        checked={todo.isDone}
        onChange={toggle}
        color="primary"
        classes={{ root: classes.checkbox }}
      />
    );
  },
);
