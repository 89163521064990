import React, { useEffect, useMemo, useState } from 'react';
import { ApolloClient, ApolloProvider, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { WebSocketLink } from '@apollo/link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { useAuthManager } from './auth/auth.hook';
import { getMainDefinition } from '@apollo/client/utilities';
import { cache, typeDefs } from './apollo';
import { persistCache } from 'apollo3-cache-persist';
import { SplashScreen } from '../components/SplashScreen';
import { RetryLink } from '@apollo/client/link/retry';

const defaultHeaders = {
  'X-Hasura-Role': 'member',
};

const retryOperations = [
  'ListTodos',
  'InsertTodo',
  'UpdateTodo',
  'ListProjects',
  'InsertProject',
  'UpdateProject',
  'BatchDone',
];
const retryLinkConfig: RetryLink.Options = {
  delay: {
    initial: 100,
    max: Infinity,
    jitter: true, // Addresses thundering herd problem
  },
  attempts: {
    max: Infinity,
    retryIf: (error, { operationName }) => {
      console.info('Retrying', error.message, operationName);
      if (error.message === 'Failed to fetch') {
        return retryOperations.includes(operationName);
      }
      return false;
    },
  },
};

export const ApolloClientProvider: React.FC = ({ children }) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>();
  const { token } = useAuthManager();
  const headers = useMemo(() => token ?
    { Authorization: `Bearer ${token}`, ...defaultHeaders } :
    defaultHeaders,
    [token],
  );

  // Pass subscriptions through websockets
  const wsLink = useMemo(() => new WebSocketLink(
    new SubscriptionClient('wss://notodos.herokuapp.com/v1/graphql', {
      reconnect: true,
      timeout: 30000,
      connectionParams: () => ({ headers }),
    }),
  ), [headers]);

  // Pass queries & mutation through https
  const httpLink = useMemo(() => new HttpLink({
    uri: 'https://notodos.herokuapp.com/v1/graphql',
    headers,
  }), [headers]);

  const link = useMemo(() => (new RetryLink(retryLinkConfig)).split(
    // split based on operation type
    ({ query }) => {
      const mainDefinition = getMainDefinition(query);
      return mainDefinition.kind === 'OperationDefinition' &&
        mainDefinition?.operation === 'subscription';
    },
    wsLink as any,
    httpLink,
  ), [wsLink, httpLink]);

  useEffect(() => {
    async function setupClient() {
      await persistCache({
        cache,
        storage: window.localStorage,
      });

      const client = new ApolloClient({
        cache,
        link,
        typeDefs,
      });
      setClient(client);
    }

    setupClient();
  }, [link]);

  if (!client) {
    return <SplashScreen />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
