import { useApolloClient } from '@apollo/client';
import { todoFragment, TodoModel } from '../../models/todos';
import { useCallback } from 'react';

export const useCachedTodo = () => {
  const client = useApolloClient();
  return useCallback((id: string | null): TodoModel | null => {
    if (!id) {
      return null;
    }
    return client.readFragment<TodoModel>({
      id: `todo:${id}`,
      fragment: todoFragment,
    });
  }, [client]);
};
