import React from 'react';
import { Button, SvgIcon, Theme, Typography } from '@material-ui/core';
import FindInPageTwoToneIcon from '@material-ui/icons/FindInPageTwoTone';
import { makeStyles } from '@material-ui/styles';
import { DisplayIf } from './DisplayIf';

interface NoResourcesProps {
  title?: string;
  children?: string;
  icon?: typeof SvgIcon;
  callToAction?: {
    call: string;
    action: () => void;
  },
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(4, 0),
    textAlign: 'center',
  },
  callToAction: {
    margin: theme.spacing(2, 0),
  },
}));


export const NoResources: React.FC<NoResourcesProps> = ({
  title = 'No resources found',
  icon: IconComponent = FindInPageTwoToneIcon,
  callToAction,
  children,
}) => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Typography variant="h2" component="span">
        <IconComponent color="inherit" fontSize="inherit" />
      </Typography>
      <Typography
        variant="button"
        component="p">
        {title}
      </Typography>
      <DisplayIf expr={!!children}>
        <Typography variant="subtitle1" align="center">
          {children}
        </Typography>
      </DisplayIf>
      <DisplayIf expr={!!callToAction}>
        <Button
          onClick={callToAction?.action}
          color="secondary"
          variant="contained"
          className={classes.callToAction}
        >
          {callToAction?.call}
        </Button>
      </DisplayIf>
    </section>
  );
};
