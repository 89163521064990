import React, { useCallback } from 'react';
import { Collapse, IconButton, ListItem, ListItemText, Theme } from '@material-ui/core';
import { DnDMoveType, TodoDropPropsModel, TodoModel } from '../../models/todos';
import { makeStyles } from '@material-ui/styles';
import { DisplayIf } from '../DisplayIf';
import { TodoList } from './TodoList';
import { TodoCheckbox } from './TodoCheckbox';
import { useTodoDrag, useTodoDrop } from '../../hooks/todos';
import { ProjectModel } from '../../models';
import { ExpandIcon } from '../ExpandIcon';
import { TodoDescription } from './TodoDescription';
import { expandedTodosVar } from '../../core/apollo';

interface TodoItemProps {
  todo: TodoModel;
  todos: Record<TodoModel['id'], TodoModel>;
  nestingLevel: number;
  parentId: ProjectModel['id'] | TodoModel['id'];
  index: number;
  allParentsIds: Array<TodoModel['id']>;
}

interface TodoItemCSSParams {
  todo: TodoModel;
  nestingLevel: number;
  collectedProps: TodoDropPropsModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  todoContent: {
    color: ({ todo }: TodoItemCSSParams) => todo.isDone ? theme.palette.text.disabled : theme.palette.text.primary,
    background: ({ collectedProps: { isOver, canDrop } }: TodoItemCSSParams) =>
      isOver && canDrop ?
        theme.palette.primary[100] :
        'none',
    fontWeight: 500,
    fontSize: '1rem',
    gridColumn: 3,
    paddingLeft: theme.spacing(1.5),
    textDecoration: ({ todo: { isDone } }: TodoItemCSSParams) => isDone ? 'line-through' : 'initial',
  },
  expander: {
    minWidth: 0,
    gridColumn: 1,
  },
  listItem: {
    padding: `0 ${theme.spacing(1)}px`,
    gridAutoColumns: `${theme.spacing(4)}px ${theme.spacing(4)}px 1fr`,
    display: 'grid',
    width: '100%',
  },
}));

export const TodoItem: React.FC<TodoItemProps> = ({ todo, todos, nestingLevel, parentId, index, allParentsIds }) => {
  const toggleExpanded = useCallback(() => {
    const expandedTodos = expandedTodosVar();
    expandedTodosVar({ ...expandedTodos, [todo.id]: !expandedTodos[todo.id] });
  }, [todo.id]);
  const isRoot = nestingLevel === 1;
  const [{ isDragging }, dragRef] = useTodoDrag({
    type: 'todo',
    todoId: todo.id,
    parentId,
    isRoot,
    index,
  });
  const [collectedProps, dropRef] = useTodoDrop({
    type: DnDMoveType.CENTRE,
    todoId: todo.id,
    parentId,
    isRoot,
    index,
    disabledIds: allParentsIds,
  });
  const classes = useStyles({ todo, collectedProps, nestingLevel });
  const description = <TodoDescription dateMode={todo.dateMode} startAt={todo.startAt} endAt={todo.endAt} />;
  return (
    <>
      <ListItem
        component="div"
        ref={dragRef}
        className={classes.listItem}
        style={{ opacity: isDragging ? 0.4 : 1 }}
      >
        <DisplayIf expr={todo.children.length > 0}>
          <IconButton onClick={toggleExpanded} size="small" className={classes.expander}>
            <ExpandIcon open={todo.isExpanded} />
          </IconButton>
        </DisplayIf>
        <TodoCheckbox todo={todo} todos={todos} />
        <ListItemText
          className={classes.todoContent}
          ref={dropRef}
          disableTypography
          primary={todo.title}
          secondary={description}
        />
      </ListItem>
      <DisplayIf expr={todo.children.length > 0}>
        <Collapse in={todo.isExpanded} timeout="auto" unmountOnExit>
          <TodoList
            todos={todos}
            todosOrder={todo.children}
            nestingLevel={nestingLevel + 1}
            parentId={todo.id}
            allParentsIds={allParentsIds}
          />
        </Collapse>
      </DisplayIf>
    </>
  );
};
