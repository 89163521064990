import React from 'react'
import MuiTextField from '@material-ui/core/TextField'
import { FieldProps } from 'formik';

interface TextFieldProps extends FieldProps {
  autoFocus: boolean;
  label?: string;
  [index: string]: any; // To allow overriding TextField props
}

export const TextField: React.FC<TextFieldProps> = React.memo((props) => {
  const { field, form, label, ...otherProps } = props;
  const lbl = label || field.name.replace(/^(.)/, m => m.toUpperCase());
  const touched = form.touched[field.name];
  const error = form.errors[field.name];
  const helperText = touched && error ? <span>{error}</span> : null;
  return (
    <MuiTextField
      id={`${field.name}-text-field`}
      error={touched && !!error}
      helperText={helperText}
      label={lbl}
      name={field.name}
      autoComplete={field.name}
      margin="dense"
      variant="outlined"
      color="primary"
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      fullWidth
      {...otherProps}
    />
  );
});
TextField.displayName = 'TextField';
