import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress, Theme } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
}

export const useLoadingBtnStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const LoadingButton: React.FC<LoadingButtonProps> = ({ loading, children, ...props }) => {
  const classes = useLoadingBtnStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button disabled={loading} {...props}>{children}</Button>
        {loading && <CircularProgress size={24} color="secondary" className={classes.buttonProgress} />}
      </div>
    </div>
  );
};
