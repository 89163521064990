import * as yup from 'yup';

export interface LoginFormModel {
  email: string;
  password: string;
}

export const loginSchema = yup.object().shape({
  email: yup.string()
    .email('Invalid email address')
    .required('This field is required'),
  password: yup.string()
    .min(8, 'Password must have at least 8 characters')
    .required('This field is required'),
});
