import React from 'react'
import { CardProps } from '@material-ui/core/Card';
import { Card, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

type FlatCardProps = CardProps

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flatCard: {
      border: `1px solid ${theme.palette.divider}`,
    },
  }),
);

export const FlatCard: React.FC<FlatCardProps> = ({ children, className,...props }) => {
  const classes = useStyles();
  return (
    <Card elevation={0} className={`${classes.flatCard} ${className}`} {...props}>
      {children}
    </Card>
  );
};
