import { gql } from '@apollo/client';

export const typeDefs = gql`
  enum NotificationType {
    INFO
    ERROR
    SUCCESS
  }
    
  type notification {
    id: ID!
    type: NotificationType!
    message: String!
  }
  
  extend type Query {
    notifications: [notification!]!
  }
  
  extend type Mutation {
    display_notification(type: NotificationType!, message: String!): notification!
    dismiss_notification(id: ID!): ID!
  }
`;
