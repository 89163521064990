import React from 'react';
import { Button, Theme, Typography } from '@material-ui/core';
import ScheduleTwoToneIcon from '@material-ui/icons/ScheduleTwoTone';
import { DateMode } from '../../../../models/todos';
import { makeStyles } from '@material-ui/styles';
import { useField } from 'formik';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  startIcon: {
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(2),
  },
}));

export const AddDateButton: React.FC = () => {
  const classes = useStyles();
  const [, , { setValue: setDateMode }] = useField<DateMode>('dateMode');
  return (
    <Typography color="textSecondary">
      <Button
        color="inherit"
        size="large"
        startIcon={<ScheduleTwoToneIcon />}
        className={classes.addButton}
        classes={{ startIcon: classes.startIcon }}
        onClick={() => setDateMode(DateMode.REMINDER)}
      >
        Add date & time
      </Button>
    </Typography>
  );
};
