import { ApolloError, gql, useQuery } from '@apollo/client';
import { NotificationType, projectFragment, ProjectModel } from '../../models';
import { useCallback, useEffect, useMemo } from 'react';
import { useNotifications } from '../../core/message-bar';
import { selectedProjectVar } from '../../core/apollo';

export const listProjectsQuery = gql`
  query ListProjects {
    projects: project {
      ...ProjectModel
    }
  }

  ${projectFragment}
`;

const listProjectsSubscription = gql`
  subscription ListProjectsSub {
    projects: project {
      ...ProjectModel
    }
  }

  ${projectFragment}
`;

export interface ListProjectsQueryResult {
  projects: ProjectModel[];
}

interface UseListProjectsResult {
  projects: ProjectModel[];
  loading: boolean;
  error: ApolloError | undefined;
  isEmpty: boolean;
}

const defaultData = { projects: [] };
export const useListProjects = (): UseListProjectsResult => {
  const {
    data: { projects } = defaultData,
    loading,
    error,
    subscribeToMore,
  } = useQuery<ListProjectsQueryResult>(listProjectsQuery, { notifyOnNetworkStatusChange: true });
  const { display } = useNotifications();
  const selectedProject = selectedProjectVar();
  useEffect(() => {
    if (!selectedProject && projects.length > 0) {
      selectedProjectVar(projects[0].id);
    }
  }, [projects, selectedProject]);

  const handleError = useCallback(function handleError(error: Error) {
    console.error(error);
    return display({
      type: NotificationType.ERROR,
      message: 'Failed to retrieve list of projects.',
    });
  }, [display]);

  useEffect(() => {
    error && handleError(error);
  }, [handleError, error]);

  useEffect(() => {
    return subscribeToMore<ListProjectsQueryResult>({
      document: listProjectsSubscription,
      updateQuery(prev, next) {
        const nextData = next.subscriptionData.data;
        const selectedProjectExists = nextData.projects.find(({ id }) => id === selectedProject);
        if (!selectedProjectExists && nextData.projects.length > 0) {
          selectedProjectVar(nextData.projects[0].id);
        }
        return nextData;
      },
    });
  }, [subscribeToMore, selectedProject]);

  return useMemo(() => ({ projects, loading, error, isEmpty: projects.length === 0 }), [projects, loading, error]);
};
