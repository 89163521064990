import React from 'react';
import { FullScreenOverlay } from '../../FullScreenOverlay';

interface CreateTodoOverlayProps {
  isOpen: boolean;
  onCancel(): void;
}

export const CreateTodoOverlay: React.FC<CreateTodoOverlayProps> = ({ children, isOpen, onCancel }) => {
  return (
    <FullScreenOverlay isOpen={isOpen} onCancel={onCancel}>
      {children}
    </FullScreenOverlay>
  );
};
