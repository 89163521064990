import React, { ReactElement } from 'react';
import { DateMode, TodoModel } from '../../models/todos';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import ScheduleIcon from '@material-ui/icons/Schedule';

interface TodoDescriptionProps {
  dateMode: TodoModel['dateMode'];
  startAt: TodoModel['startAt'];
  endAt: TodoModel['endAt'];
}

export const TodoDescription = React.memo(
  function TodoDescription({ dateMode, startAt, endAt }: TodoDescriptionProps): ReactElement | null {
    switch (dateMode) {
      case DateMode.NONE:
        return null;
      case DateMode.ALL_DAY: {
        const start = format(new Date(startAt), 'dd/MM/yyyy');
        return (
          <Typography variant="caption" component="p" color="textSecondary">
            <ScheduleIcon style={{ fontSize: 12 }} />&nbsp;
            {start}
          </Typography>
        );
      }
      case DateMode.REMINDER: {
        const start = format(new Date(startAt), 'dd/MM/yyyy H:mm');
        return (
          <Typography variant="caption" component="p" color="textSecondary">
            <ScheduleIcon style={{ fontSize: 12 }} />&nbsp;
            {start}
          </Typography>
        );
      }
      case DateMode.EVENT: {
        const start = format(new Date(startAt), 'dd/MM/yyyy H:mm');
        const end = format(new Date(endAt), 'dd/MM/yyyy H:mm');
        return (
          <Typography variant="caption" component="p" color="textSecondary">
            <ScheduleIcon style={{ fontSize: 12 }} />&nbsp;{`${start} - ${end}`}
          </Typography>
        );
      }
    }
  },
);
