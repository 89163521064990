import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { projectSchema } from '../../models';
import Add from '@material-ui/icons/Add';
import { Field, Formik } from 'formik';
import { TextField } from '../Forms/TextField';
import { LoadingButton } from '../LoadingButton';
import { InsertProjectInputModel } from '../../models/projects';
import { useCreateProject } from '../../hooks/projects/create-project.hook';

export interface CreateProjectModalProps {
  open: boolean;
  onClose(): void;
}

const initialInput: InsertProjectInputModel = {
  hidden: false,
  title: '',
  color: '#0088FF',
};

export const CreateProjectModal: React.FC<CreateProjectModalProps> = ({ open, onClose }) => {
  const { loading, insertProject } = useCreateProject({ onCompleted: onClose });
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create new project</DialogTitle>
      <Formik
        initialValues={initialInput}
        onSubmit={insertProject}
        validationSchema={projectSchema}
        validateOnBlur
        validateOnChange>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Field name="color" placeholder="Icon color" component={TextField} fullWidth />
              <Field name="title" placeholder="Project title" component={TextField} fullWidth />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary" disabled={loading}>
                Cancel
              </Button>
              <LoadingButton type="submit" color="primary" variant="text" loading={loading}>
                <Add /> Create
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
