import { gql } from '@apollo/client';
import { todoFragment, TodoModel } from '../../../models/todos';
import { projectFragment, ProjectModel } from '../../../models';
import { UpdateTodoInputModel } from '../../../models/todos/update-todo-input.model';
import { UpdateProjectInputModel } from '../../../models/projects';
import { UpdateTodoMutationResult } from '../update-todo.hook';
import { UpdateProjectMutationResult } from '../../projects/update-project.hook';

export const updateProjectAndTodoMutation = gql`
  mutation UpdateProjectAndTodo(
    $todoId: uuid!,
    $todoInput: todo_set_input!
    $projectId: uuid!,
    $projectInput: project_set_input!
  ) {
    todo: update_todo_by_pk(pk_columns: {id: $todoId}, _set: $todoInput) {
      __typename
      ...TodoModel
    }
    project: update_project_by_pk(pk_columns: {id: $projectId}, _set: $projectInput) {
      __typename
      ...ProjectModel
    }
  }

  ${todoFragment}
  ${projectFragment}
`;

export interface UpdateProjectAndTodoInput {
  todoId: TodoModel['id'];
  projectId: ProjectModel['id'];
  todoInput: UpdateTodoInputModel['input'];
  projectInput: UpdateProjectInputModel['input'];
}

export type UpdateProjectAndTodoResult = UpdateTodoMutationResult & UpdateProjectMutationResult;

export const updateTodoPairMutation = gql`
  mutation UpdateProjectAndTodo(
    $firstTodoId: uuid!,
    $firstTodoInput: todo_set_input!
    $secondTodoId: uuid!,
    $secondTodoInput: todo_set_input!
  ) {
    first: update_todo_by_pk(pk_columns: {id: $firstTodoId}, _set: $firstTodoInput) {
      ...TodoModel
    }
    second: update_todo_by_pk(pk_columns: {id: $secondTodoId}, _set: $secondTodoInput) {
      ...TodoModel
    }
  }

  ${todoFragment}
`;

export interface UpdateTodoPairInput {
  firstTodoId: TodoModel['id'];
  firstTodoInput: UpdateTodoInputModel['input'];
  secondTodoId: TodoModel['id'];
  secondTodoInput: UpdateTodoInputModel['input'];
}

export type UpdateTodoPairResult = {
  first: UpdateTodoMutationResult['todo'];
  second: UpdateTodoMutationResult['todo'];
}
