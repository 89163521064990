import ConfirmationNumberTwoTone from '@material-ui/icons/ConfirmationNumberTwoTone';
import { ActivationForm } from './ActivationForm';
import React from 'react';
import { ActivationFormModel } from '../../../models';
import { AuthCard } from '../AuthCard';

interface ActivationCardProps {
  onActivate(formValues: ActivationFormModel): Promise<void>;
  onResendCode(): void;
}

export const ActivationCard: React.FC<ActivationCardProps> = ({ onActivate, onResendCode }) => (
  <AuthCard
    onSubmit={onActivate}
    formComponent={ActivationForm}
    title="Activate the account"
    submitText="Verify"
    iconComponent={ConfirmationNumberTwoTone}
    info={`Thank you for signing up.\nPlease provide below the activation code that was sent to you:`}
    linkAction={{
      onClick: onResendCode,
      text: 'Resend code?',
    }}
  />
);
