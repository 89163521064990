import { createContext, MutableRefObject, useCallback, useMemo, useRef, useState } from 'react';

export enum View {
  UNSET = 'unset',
  TODO_LIST = 'todo-list',
  CREATE_TODO = 'create-todo',
}

export interface LayoutSpec {
  actionsRef: MutableRefObject<HTMLDivElement | null>;
  fabRef: MutableRefObject<HTMLDivElement | null>;
  sideSheetRef: MutableRefObject<HTMLDivElement | null>;
  currentView: View;
  isSideSheetOpen: boolean;
  toggleSideSheet(next?: boolean): void;
  setCurrentView(view: View): void;
}

export const LayoutManagerContext = createContext<LayoutSpec | undefined>(undefined);

export const useLayoutManager = (): LayoutSpec => {
  const actionsRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
  const fabRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
  const sideSheetRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
  const [currentView, setCurrentView] = useState<View>(View.UNSET);
  const [isSideSheetOpen, setSideSheetOpen] = useState(false);
  const toggleSideSheet = useCallback(
    (next = !isSideSheetOpen) => {
      typeof next === 'boolean' ?
        setSideSheetOpen(next) :
        setSideSheetOpen(!isSideSheetOpen)
    },
    [setSideSheetOpen, isSideSheetOpen],
  );
  return useMemo(() => ({
    actionsRef,
    fabRef,
    sideSheetRef,
    currentView,
    setCurrentView,
    isSideSheetOpen,
    toggleSideSheet,
  }), [isSideSheetOpen, toggleSideSheet, actionsRef, currentView, setCurrentView, fabRef]);
};
