import React from 'react';
import { RedirectUser } from '../RedirectUser';
import { SingleColumn } from '../../PageLayout';
import { LoginCard } from './LoginCard';
import { useCredentialsLogin } from '../../../core/auth/credentials-login.hook';
import { Header } from '../../../layout/components/Header';
import { SignInButton } from '../SignInButton';
import { SignUpButton } from '../SignUpButton';

export const LoginView: React.FC = () => {
  const onLogin = useCredentialsLogin();
  const header = (
    <Header title="NoTodos">
      <SignInButton />
      <SignUpButton />
    </Header>
  );
  return (
    <RedirectUser to="/todos">
      <SingleColumn header={header}>
        <LoginCard onLogin={onLogin} />
      </SingleColumn>
    </RedirectUser>
  );
};
