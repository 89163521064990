import React, { ReactElement } from 'react';
import { Container, Snackbar, Theme, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import { Sidebar } from '../Sidebar';
import { BottomNav } from '../BottomNav';
import { makeStyles } from '@material-ui/styles';
import { Loading } from '../Loading';
import { View } from './layout-manager.hook';
import { useNotifications } from '../../core/message-bar';
import { Alert, AlertProps } from '@material-ui/lab';
import { NotificationType } from '../../models';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    flexGrow: 1,
    height: '100%',
    margin: 0,
    padding: 0,
  },
  content: {
    flexGrow: 1,
    padding: 0,
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.mixins.toolbar.height,
      marginTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      overflowY: 'auto',
    },
  },
}));

interface SidebarLayoutProps {
  header: ReactElement;
  loading?: boolean;
  fab?: ReactElement;
  actions?: ReactElement;
  view?: View;
}

const getSeverity = (type: NotificationType): AlertProps['severity'] => {
  switch (type) {
    case NotificationType.SUCCESS:
      return 'success';
    case NotificationType.INFO:
      return 'info';
    case NotificationType.ERROR:
      return 'error';
  }
};

export const SidebarLayout: React.FC<SidebarLayoutProps> = React.memo(
  function SidebarLayout({ children, header, loading = false, fab, view, actions }) {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { notifications, dismiss } = useNotifications();
    return (
      <>
        {header}
        <Container className={classes.root} maxWidth={false}>
          {notifications.map(({ id, message, type }) => (
            <Snackbar key={id} open={true} autoHideDuration={6000} onClose={() => dismiss(id)}>
              <Alert onClose={() => dismiss(id)} severity={getSeverity(type)}>
                {message}
              </Alert>
            </Snackbar>
          ))}

          <Loading isLoading={loading}>
            {isDesktop ? <Sidebar /> : <BottomNav fab={fab} view={view} actions={actions} />}
            <div className={classes.content}>
              <Toolbar />
              {children}
            </div>
          </Loading>
        </Container>
      </>
    );
  },
);
