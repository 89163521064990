import * as yup from 'yup';

export interface RegisterFormModel {
  email: string;
  name: string;
  password: string;
  confirmPassword: string;
}

export const registerSchema = yup.object().shape({
  email: yup.string()
    .email('Invalid email address')
    .required('Please provide your email address'),
  name: yup.string().required('Please provide your name'),
  password: yup.string()
    .min(8, 'Password must have at least 8 characters')
    .required('This field is required'),
  confirmPassword: yup.string()
    .min(8, 'Password must have at least 8 characters')
    .required('This field is required')
    .oneOf([yup.ref('password'), ''], 'Passwords must match'),
});
