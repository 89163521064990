import React, { ReactElement } from 'react';
import { FormControlWithIcon } from '../../../FormControlWithIcon';
import ScheduleTwoToneIcon from '@material-ui/icons/ScheduleTwoTone';
import { Field } from 'formik';
import { DatePicker, TimePicker } from 'formik-material-ui-pickers';
import { makeStyles } from '@material-ui/styles';
import { Theme, useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  dateAndTime: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  dateField: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  timeField: {
    flexBasis: '30%',
  },
}));

interface DateTimePickerProps {
  name: string;
  withTime?: boolean;
}

export const DateTimePicker = ({ name, withTime }: DateTimePickerProps): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <FormControlWithIcon
      classes={{ formControl: classes.dateAndTime }}
      icon={ScheduleTwoToneIcon}
    >
      <Field
        component={DatePicker}
        variant={isDesktop ? 'inline' : 'dialog'}
        label="Date"
        name={name}
        className={classes.dateField}
        format="yyyy/MM/dd"
      />
      {withTime &&
      <Field
        ampm={false}
        component={TimePicker}
        variant={isDesktop ? 'inline' : 'dialog'}
        label="Time"
        name={name}
        className={classes.timeField}
        clearable
      />
      }
    </FormControlWithIcon>
  );
};
