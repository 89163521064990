import React from 'react';
import { AuthCard } from '../AuthCard';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import VerifiedUserTwoTone from '@material-ui/icons/VerifiedUserTwoTone';
import { Paths } from '../../../paths';
import { ForgotPasswordFormModel } from '../../../models';

interface ForgotPasswordCardProps {
  onRequestCode(formValues: ForgotPasswordFormModel): Promise<void>;
}

export const ForgotPasswordCard: React.FC<ForgotPasswordCardProps> = ({ onRequestCode }) => (
  <AuthCard
    onSubmit={onRequestCode}
    formComponent={ForgotPasswordForm}
    title="Forgotten password?"
    iconComponent={VerifiedUserTwoTone}
    submitText="Request code"
    info="Provide your email and we'll send you code allowing to reset the password."
    alternativeAction={{
      targetPath: Paths.LOGIN,
      actionText: 'Cancel',
    }}
  />
);
