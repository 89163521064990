import React from 'react'

interface DisplayIfProps {
  expr: boolean;
  onElse?: React.ReactElement;
}

export const DisplayIf: React.FC<DisplayIfProps> = React.memo(({ expr, children, onElse }) => (
  expr ? <>{children}</> : <>{onElse}</>
));
DisplayIf.displayName = 'DisplayIf';
