import { ApolloClient, FetchResult, useApolloClient } from '@apollo/client';
import {
  UpdateProjectAndTodoInput,
  updateProjectAndTodoMutation,
  UpdateProjectAndTodoResult,
  UpdateTodoPairInput,
  updateTodoPairMutation,
  UpdateTodoPairResult,
} from './move-todo.mutation';
import { useCachedTodo } from '../cached-todo.hook';
import { ProjectModel, TodoModel } from '../../../models';
import { useCallback } from 'react';
import { DnDMoveType, TodoDragModel, TodoDropModel } from '../../../models/todos';
import { updateProjectMutation, UpdateProjectMutationResult } from '../../projects/update-project.hook';
import { UpdateProjectInputModel } from '../../../models/projects';
import { useCachedProject } from '../../projects';
import { UpdateTodoInputModel } from '../../../models/todos/update-todo-input.model';
import { updateTodoMutation, UpdateTodoMutationResult } from '../update-todo.hook';

interface StrategyInput {
  client: ApolloClient<object>;
  source: TodoDragModel;
  target: TodoDropModel;
  getTodo(id: string): TodoModel | null;
  getProject(id: string): ProjectModel | null;
}
// TODO Strategy from root to center
function moveWithinProject({ client, source, target, getProject }: StrategyInput): Promise<FetchResult<UpdateProjectMutationResult>> {
  const project = getProject(target.parentId);
  if (!project) {
    return Promise.reject(new Error('Todo moved within project that doesn\'t exist or access is denied for it'));
  }
  const nextTodosOrder = [...project.todos_order];
  // Remove dragged element from its original position
  nextTodosOrder.splice(source.index, 1);
  // Insert dragged element before/after drop target
  nextTodosOrder.splice(target.index, 0, source.todoId);
  const variables: UpdateProjectInputModel = { id: project.id, input: { todos_order: nextTodosOrder } };
  return client.mutate<UpdateProjectMutationResult, UpdateProjectInputModel>({
    mutation: updateProjectMutation,
    variables,
    optimisticResponse: { project: { ...project, ...variables.input } },
  });
}

moveWithinProject.predicate = (dragModel: TodoDragModel, dropModel: TodoDropModel) => (
  dragModel.isRoot && dropModel.isRoot && dragModel.parentId === dropModel.parentId && dropModel.type !== DnDMoveType.CENTRE
);

function moveWithinTodo({ client, getTodo, source, target }: StrategyInput) {
  const isBecomingChild = target.type === DnDMoveType.CENTRE;
  const todo = getTodo(isBecomingChild ? target.todoId : target.parentId);
  if (!todo) {
    return Promise.reject(new Error('Todo moved within another todo that doesn\'t exist or access is denied for it'));
  }
  const nextChildren = [...todo.children];
  // Remove dragged element from its original position
  nextChildren.splice(source.index, 1);
  // Insert dragged element before/after drop target OR at the end when its becoming a child
  const nextIndex = isBecomingChild ? todo.children.length : target.index + Number(target.type === DnDMoveType.AFTER);
  nextChildren.splice(nextIndex, 0, source.todoId);
  const variables: UpdateTodoInputModel = { id: todo.id, input: { children: nextChildren } };
  return client.mutate<UpdateTodoMutationResult, UpdateTodoInputModel>({
    mutation: updateTodoMutation,
    variables,
    optimisticResponse: { todo: { ...todo, ...variables.input } },
  });
}

moveWithinTodo.predicate = (dragModel: TodoDragModel, dropModel: TodoDropModel) => (
  !dragModel.isRoot && !dropModel.isRoot && dragModel.parentId === dropModel.parentId
);

function moveToRootLevel({ client, getTodo, getProject, source, target }: StrategyInput) {
  const todo = getTodo(source.parentId);
  const project = getProject(target.parentId);
  if (!todo) {
    return Promise.reject(new Error('Todo moved from another todo that doesn\'t exist or access is denied for it'));
  }
  if (!project) {
    return Promise.reject(new Error('Todo moved to a project that doesn\'t exist or access is denied for it'));
  }
  const children = [...todo.children];
  const todos_order = [...project.todos_order];
  // Remove dragged element from its original position
  children.splice(source.index, 1);
  // Insert dragged element before/after drop target
  todos_order.splice(target.index + Number(target.type === DnDMoveType.AFTER), 0, source.todoId);
  const variables: UpdateProjectAndTodoInput = {
    todoId: todo.id,
    projectId: project.id,
    todoInput: { children },
    projectInput: { todos_order },
  };
  return client.mutate<UpdateProjectAndTodoResult, UpdateProjectAndTodoInput>({
    mutation: updateProjectAndTodoMutation,
    variables,
    optimisticResponse: {
      todo: { ...todo, ...variables.todoInput },
      project: { ...project, ...variables.projectInput },
    },
  });
}

moveToRootLevel.predicate = (dragModel: TodoDragModel, dropModel: TodoDropModel) => (
  !dragModel.isRoot && dropModel.isRoot
);

function moveFromRootLevel({ client, getTodo, getProject, source, target }: StrategyInput) {
  const isBecomingChild = target.type === DnDMoveType.CENTRE;
  const project = getProject(source.parentId);
  const todo = getTodo(isBecomingChild ? target.todoId : target.parentId);
  if (!project) {
    return Promise.reject(new Error('Todo moved to a project that doesn\'t exist or access is denied for it'));
  }
  if (!todo) {
    return Promise.reject(new Error('Todo moved from another todo that doesn\'t exist or access is denied for it'));
  }
  const children = [...todo.children];
  const todos_order = [...project.todos_order];
  // Remove dragged element from its original position
  todos_order.splice(source.index, 1);
  // Insert dragged element before/after drop target or at the end when becoming a child
  const position = isBecomingChild ? todo.children.length : target.index + Number(target.type === DnDMoveType.AFTER);
  children.splice(position, 0, source.todoId);
  const variables: UpdateProjectAndTodoInput = {
    todoId: todo.id,
    projectId: project.id,
    todoInput: { children },
    projectInput: { todos_order },
  };
  return client.mutate<UpdateProjectAndTodoResult, UpdateProjectAndTodoInput>({
    mutation: updateProjectAndTodoMutation,
    variables,
    optimisticResponse: {
      todo: { ...todo, ...variables.todoInput },
      project: { ...project, ...variables.projectInput },
    },
  });
}

moveFromRootLevel.predicate = (dragModel: TodoDragModel, dropModel: TodoDropModel) => (
  dragModel.isRoot && (!dropModel.isRoot || dropModel.type === DnDMoveType.CENTRE)
);

function moveBetweenTodos({ client, getTodo, source, target }: StrategyInput) {
  const first = getTodo(source.parentId);
  const second = getTodo(target.parentId);
  if (!first) {
    return Promise.reject(new Error('Todo moved from parent todo that doesn\'t exist or access is denied for it'));
  }
  if (!second) {
    return Promise.reject(new Error('Todo moved to parent todo that doesn\'t exist or access is denied for it'));
  }
  const prevParentChildren = [...first.children];
  const nextParentChildren = [...second.children];
  // Remove dragged element from its original position
  prevParentChildren.splice(source.index, 1);
  // Insert dragged element before/after drop target
  nextParentChildren.splice(target.index + Number(target.type === DnDMoveType.AFTER), 0, source.todoId);
  const variables: UpdateTodoPairInput = {
    firstTodoId: first.id,
    firstTodoInput: { children: prevParentChildren },
    secondTodoId: second.id,
    secondTodoInput: { children: nextParentChildren },
  };
  return client.mutate<UpdateTodoPairResult, UpdateTodoPairInput>({
    mutation: updateTodoPairMutation,
    variables,
    optimisticResponse: {
      first: { ...first, ...variables.firstTodoInput },
      second: { ...second, ...variables.secondTodoInput },
    },
  });
}

moveBetweenTodos.predicate = (dragModel: TodoDragModel, dropModel: TodoDropModel) => (
  !dragModel.isRoot && !dropModel.isRoot && dragModel.parentId !== dropModel.parentId
);

const strategies = [
  moveWithinProject,
  moveWithinTodo,
  moveToRootLevel,
  moveFromRootLevel,
  moveBetweenTodos,
];

type Strategy = (input: StrategyInput) => Promise<any>;

export const useTodoMove = () => {
  const client = useApolloClient();
  const getTodo = useCachedTodo();
  const getProject = useCachedProject();

  return useCallback(function onTodoMove(source: TodoDragModel, target: TodoDropModel) {
    const strategy: Strategy | undefined = strategies.find(({ predicate }) => predicate(source, target));
    if (!strategy) {
      return Promise.reject(new Error('Could not find appropriate move strategy'));
    }
    return strategy({
      client,
      source,
      target,
      getTodo,
      getProject,
    })
      .catch(error => {
        console.error('In strategy', strategy.name);
        console.error(error);
      });
  }, [client, getTodo, getProject]);
};
