import React from 'react';
import { Button } from '@material-ui/core';
import { AdapterLink } from '../AdapterLink';
import { Paths } from '../../paths';

interface SignInButtonProps {
  className?: string;
}

export const SignInButton: React.FC<SignInButtonProps> = ({ className }) => (
  <Button color="primary" component={AdapterLink} to={Paths.LOGIN} className={className}>Login</Button>
);
