import { gql, useMutation } from '@apollo/client';
import { InsertProjectInputModel } from '../../models/projects';
import { NotificationType, projectFragment, ProjectModel } from '../../models';
import { useCallback, useEffect, useMemo } from 'react';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { useNotifications } from '../../core/message-bar';
import { MutationUpdaterFn } from '@apollo/client/core';
import { listProjectsQuery, ListProjectsQueryResult } from './list-projects.hook';
import { v4 as uuid } from 'uuid';

const insertProjectMutation = gql`
    mutation InsertProject($input: project_insert_input!) {
        project: insert_project_one(object: $input) {
            ...ProjectModel
        }
    }

    ${projectFragment}
`;

interface InsertProjectResult {
  project: ProjectModel & { __typename: 'project' };
}

const onUpdate: MutationUpdaterFn<InsertProjectResult> = (cache, { data }) => {
  if (!data) {
    return;
  }
  const { project } = data;
  const { projects } = cache.readQuery<ListProjectsQueryResult, ListProjectsQueryResult>({ query: listProjectsQuery })!;
  cache.writeQuery({
    query: listProjectsQuery,
    data: { projects: [...projects, project] },
  });
};

export const useCreateProject = (options?: MutationHookOptions<InsertProjectResult>) => {
  const { display } = useNotifications();
  const [insertProject, result] = useMutation<InsertProjectResult>(insertProjectMutation, options);
  const { error: { message: errMsg } = {} } = result;

  const handleError = useCallback(function handleError(error) {
    console.error(error);
    return display({
      type: NotificationType.ERROR,
      message: 'Failed to create the project',
    });
  }, [display]);

  const performMutation = useCallback((input: InsertProjectInputModel) => {
    if (result.called) {
      return;
    }
    const id = uuid();
    return insertProject({
      variables: { input },
      optimisticResponse: {
        project: {
          __typename: 'project',
          id,
          todos: [],
          collaborators: [],
          owner: 'self',
          todos_order: [],
          is_selected: false,
          ...input,
        },
      },
      update: onUpdate,
    })
      .catch(handleError);
  }, [insertProject, handleError, result.called]);

  useEffect(() => {
    errMsg && handleError(errMsg);
  }, [handleError, errMsg]);

  return useMemo(() => ({
    insertProject: performMutation,
    ...result,
  }), [result, performMutation]);
};
