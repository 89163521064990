import React, { ReactElement, ReactNode } from 'react';
import { AppBar, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import logo from '../../../images/logo.png';

interface HeaderProps {
  title: ReactNode;
  displayIcon?: boolean;
  navigation?: ReactElement;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey['600'],
  },
  brand: {
    marginLeft: -theme.spacing(1),
    marginRight: theme.spacing(2),
    width: 48,
    height: 48,
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
  actions: {
    whiteSpace: 'nowrap',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

export const Header: React.FC<HeaderProps> = React.memo(
  function Header({ title, displayIcon = true, navigation, children }) {
    const classes = useStyles();
    return (
      <AppBar position="fixed" color="inherit" elevation={0} className={classes.appBar}>
        <Toolbar>
          {displayIcon && <img src={logo} alt="NoTodos Logo" className={classes.brand} />}
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <span className={classes.actions}>
          {children}
          </span>
        </Toolbar>
        { navigation && <Hidden mdUp>
          {navigation}
        </Hidden>}
      </AppBar>
    );
  },
);
