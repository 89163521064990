import { TodoDragModel, TodoDropModel, TodoDropPropsModel } from '../../../models/todos';
import { DropTargetMonitor, useDrop } from 'react-dnd';

export const useTodoDrop = (dropModel: TodoDropModel) => {
  return useDrop({
    accept: ['todo'],
    drop(item: TodoDragModel, monitor: DropTargetMonitor): TodoDropModel {
      return dropModel;
    },
    collect(monitor): TodoDropPropsModel {
      return { isOver: monitor.isOver(), canDrop: monitor.canDrop() };
    },
    canDrop(_: any, monitor: DropTargetMonitor): boolean {
      // TODO Ensure that item cannot be dropped on its children
      const { disabledIds = [] } = dropModel;
      const item: TodoDropModel = monitor.getItem();
      return item.todoId !== dropModel.todoId &&
        item.todoId !== dropModel.parentId &&
        item.parentId !== dropModel.todoId &&
        !disabledIds.includes(item.todoId);
    },
  });
};
