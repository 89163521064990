import { gql, MutationUpdaterFn, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { TodoFormModel, todoFragment, TodoModel } from '../../models/todos';
import { useCallback, useEffect, useMemo } from 'react';
import { NotificationType, projectFragment, ProjectModel } from '../../models';
import { InsertTodoInputModel } from '../../models/todos/insert-todo-input.model';
import { listProjectsQuery, ListProjectsQueryResult, useListProjects } from '../projects';
import { v4 as uuid } from 'uuid';
import { listTodosQuery, ListTodosQueryResult } from './list-todos.hook';
import { useNotifications } from '../../core/message-bar';

const insertTodoQuery = gql`
  mutation InsertTodo(
    $projectId: uuid!
    $todoInput: todo_insert_input!
    $projectInput: project_set_input!
  ) {
    todo: insert_todo_one(object: $todoInput) {
      __typename
      ...TodoModel
    }
    project: update_project_by_pk(
      pk_columns: { id: $projectId },
      _set: $projectInput,
    ) {
      __typename
      ...ProjectModel
    }
  }

  ${todoFragment}
  ${projectFragment}
`;

interface InsertTodoInput {
  projectId: string;
  todoInput: InsertTodoInputModel;
  projectInput: { todos_order: string[] };
}

interface InsertTodoResult {
  todo: TodoModel & { __typename: 'todo' };
  project: ProjectModel & { __typename: 'project' };
}

const onUpdate: MutationUpdaterFn<InsertTodoResult> = (cache, { data }) => {
  if (!data) {
    return;
  }
  const { todo, project } = data;
  const { todos } = cache.readQuery<ListTodosQueryResult, ListTodosQueryResult>({
    query: listTodosQuery,
  })!;
  const { projects } = cache.readQuery<ListProjectsQueryResult, ListProjectsQueryResult>({ query: listProjectsQuery })!;
  cache.writeQuery({ query: listTodosQuery, data: { todos: [...todos, todo] } });
  cache.writeQuery({
    query: listProjectsQuery,
    data: { projects: projects.map(projectItem => projectItem.id === project.id ? project : projectItem) },
  });
};

export const useCreateTodo = (options?: MutationHookOptions<InsertTodoResult, InsertTodoInput>) => {
  const { display } = useNotifications();
  const { projects } = useListProjects();
  const [insertTodo, result] = useMutation<InsertTodoResult, InsertTodoInput>(insertTodoQuery, options);
  const { error: { message: errMsg } = {} } = result;
  const onFailure = useCallback((error) => {
    console.error(error);
    return display({
      type: NotificationType.ERROR,
      message: 'Failed to create a todo',
    });
  }, [display]);

  const performMutation = useCallback(({ startDate, endDate, ...formValues }: TodoFormModel) => {
    if (result.called) {
      return;
    }
    const id = uuid();
    const todoInput: InsertTodoInputModel = {
      ...formValues,
      id,
      isDone: false,
      isPinned: false,
      parentId: null,
      startAt: startDate.toUTCString(),
      endAt: endDate.toUTCString(),
    };
    const project = projects.find(({ id }) => id === todoInput.projectId);
    if (!project) {
      display({
        type: NotificationType.ERROR,
        message: 'Selected project for the new todo doesn\'t exist.',
      });
      throw new Error('Selected project for the new todo doesn\'t exist.');
    }
    const { id: projectId, todos_order: todosOrder } = project;
    const todos_order = [...todosOrder, id];
    return insertTodo({
      variables: {
        projectId,
        todoInput,
        projectInput: { todos_order },
      },
      optimisticResponse: {
        todo: {
          __typename: 'todo',
          ...todoInput,
          children: [],
          labels: [],
          comments: [],
          owner: project.owner,
          isExpanded: false,
        },
        project: {
          __typename: 'project',
          ...project,
          todos_order,
        },
      },
      update: onUpdate,
    })
      .catch(onFailure);
  }, [display, projects, insertTodo, onFailure, result.called]);

  useEffect(() => {
    errMsg && onFailure(errMsg);
  }, [onFailure, errMsg]);

  return useMemo(() => ({
    insertTodo: performMutation,
    ...result,
  }), [performMutation, result]);
};
