import React, { forwardRef } from 'react'
import { Field, Form, FormikProps } from 'formik';
import { LoginFormModel, loginSchema } from 'models';
import { TextField } from '../../Forms';
import { FormikRef } from '../../../models/types';
import { FormikWithRef } from '../../FormikWithRef';

interface LoginFormProps {
  onSubmit(values: LoginFormModel): Promise<void>;
}

const initialValues: LoginFormModel = {
  email: '',
  password: '',
};

export const LoginFormBody: React.FC<LoginFormProps & FormikProps<LoginFormModel>> = ({ submitForm }) => (
  <Form onKeyDown={(e) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }}>
    <Field type="email" name="email" component={TextField} autoFocus />
    <Field type="password" name="password" component={TextField} />
  </Form>
);

export const LoginForm = forwardRef((
  { onSubmit }: LoginFormProps,
  ref: FormikRef<LoginFormModel>,
) => (
  <FormikWithRef
    formRef={ref}
    initialValues={initialValues}
    validationSchema={loginSchema}
    onSubmit={onSubmit}
    component={LoginFormBody}
    validateOnBlur
  />
));
LoginForm.displayName = 'LoginForm';
