import React, { ReactNode } from 'react';
import { Redirect } from 'react-router';
import { AuthStatus, useAuthManager } from '../auth.hook';
import { Paths } from '../../../paths';
import { SplashScreen } from '../../../components/SplashScreen';

interface PublicRoutesGroupProps {
  children: ReactNode;
  onSignedInPath: Paths;
}

export const PublicRoutesGroup = React.memo(
  function PublicRoutesGroup({ children, onSignedInPath }: PublicRoutesGroupProps) {
    const { status } = useAuthManager();
    if (status === AuthStatus.LOADING) {
      return <SplashScreen />;
    }
    if (status === AuthStatus.SIGNED_IN) {
      return <Redirect to={{ pathname: onSignedInPath }} />;
    }
    return <>{children}</>;
  },
);
