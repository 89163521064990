import React from 'react';
import { Typography } from '@material-ui/core';
import { SingleColumn } from '../PageLayout';
import { Header } from '../../layout/components/Header';
import { SignInButton } from '../AuthView/SignInButton';
import { SignUpButton } from '../AuthView/SignUpButton';

export const HomeView: React.FC = () => {
  const header = (
    <Header title="NoTodos">
      <SignInButton />
      <SignUpButton />
    </Header>
  );

  return (
    <SingleColumn header={header}>
      <Typography variant="h4" align="center">
        Notes, todos and more, all in one place
      </Typography>
    </SingleColumn>
  );
};
