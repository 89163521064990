import { useMemo } from 'react';

interface Deferred<T> {
  promise: Promise<T>;
  resolve(value: T): void;
  reject(error?: string): void;
}

export const useDeferred = <T = void>(): Deferred<T> => {
  return useMemo(() => {
    const deferred: any = {};
    deferred.promise = new Promise<T>((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    })
    return deferred;
  }, []);
};
