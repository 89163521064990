import React from 'react';
import { Button } from '@material-ui/core';
import { AdapterLink } from '../AdapterLink';
import { enableSignUp } from '../../feature-flags';
import { Paths } from '../../paths';

interface SignUpButtonProps {
  className?: string;
}

export const SignUpButton: React.FC<SignUpButtonProps> = ({ className }) => (
  enableSignUp ?
    <Button
      color="primary"
      variant="contained"
      component={AdapterLink}
      to={Paths.REGISTER}
      className={className}
    >
      Sign up
    </Button> :
    null
);
