import React from 'react';
import { RedirectUser } from '../RedirectUser';
import { SingleColumn } from '../../PageLayout';
import { RegisterCard } from './RegisterCard';
import { useCredentialsSignup } from '../../../core/auth/credentials-signup.hook';
import { Header } from '../../../layout/components/Header';
import { SignInButton } from '../SignInButton';
import { SignUpButton } from '../SignUpButton';

export const RegisterView: React.FC = () => {
  const onSignUp = useCredentialsSignup();
  const header = (
    <Header title="NoTodos">
      <SignInButton />
      <SignUpButton />
    </Header>
  );

  return (
    <RedirectUser to="/todos">
      <SingleColumn header={header}>
        <RegisterCard onSignUp={onSignUp} />
      </SingleColumn>
    </RedirectUser>
  );
};
