import { useCallback } from 'react';
import { User } from '@firebase/auth-types';
import { timeout } from '../../utils/timeout';
import { useFirebase } from '../firebase';
import { useDeferred } from '../../hooks/deferred.hook';

export const useWaitForClaims = () => {
  const { database } = useFirebase();
  const { promise, resolve, reject } = useDeferred();

  return useCallback(function waitForClaims(user: User) {
    const metadataRef = database.ref(`metadata/${user.uid}/refreshTime`);
    const callback = metadataRef.on('value', async (data) => {
      if (!data.exists) return;
      // Force refresh to pick up the latest custom claims changes.
      try {
        const { claims } = await user.getIdTokenResult(true);
        if (claims['https://hasura.io/jwt/claims']) {
          return resolve();
        }
        reject('Failed to fetch user\'s claims.');
      } catch (error) {
        reject(error);
      }
    });

    const unsubscribe = () => {
      metadataRef.off('value', callback);
    };

    return Promise.race([
      promise.then(unsubscribe),
      timeout(30000, 'Timed out fetching user claims'),
    ]);
  }, [resolve, reject, database, promise]);
}
