import React from 'react';
import { FormControlWithIcon } from '../FormControlWithIcon';
import AllInboxTwoToneIcon from '@material-ui/icons/AllInboxTwoTone';
import { Select } from './Select';
import { ListItemIcon, MenuItem, Theme } from '@material-ui/core';
import { ProjectIcon } from '../ProjectList/ProjectIcon';
import { ProjectModel } from '../../models';
import { makeStyles } from '@material-ui/styles';

interface ProjectSelectProps {
  projects: ProjectModel[];
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
  },
  menuPaper: {
    maxHeight: '50vh',
  },
  dropdown: {
    '& svg': {
      transform: `translateY(${theme.spacing(1)}px)`,
    },
  },
}));

export const ProjectSelect: React.FC<ProjectSelectProps> = ({ projects }) => {
  const classes = useStyles();
  return (
    <FormControlWithIcon
      classes={classes}
      icon={AllInboxTwoToneIcon}>
      <Select
        name="projectId"
        label="Project"
        className={classes.dropdown}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
      >
        {projects.map(project => (
          <MenuItem key={project.id} value={project.id}>
            <ListItemIcon>
              <ProjectIcon selected={project.is_selected} color={project.color} />
            </ListItemIcon>
            {project.title}
          </MenuItem>
        ))}
      </Select>
    </FormControlWithIcon>
  );
};
