import React, { forwardRef } from 'react';
import { Field, Form, FormikProps } from 'formik';
import { ActivationFormModel, activationSchema } from 'models';
import { TextField } from '../../Forms';
import { FormikWithRef } from '../../FormikWithRef';
import { FormikRef } from '../../../models/types';

interface ActivationFormProps {
  onSubmit(values: ActivationFormModel): Promise<void>;
}

const initialValues: ActivationFormModel = {
  username: '',
  code: '',
};

const ActivationFormBody: React.FC<FormikProps<ActivationFormModel>> = ({ submitForm }) => (
  <Form onKeyDown={(e) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }}>
    <Field type="code" name="code" placeholder="Activation code" component={TextField} autoFocus />
  </Form>
);

export const ActivationForm = forwardRef((
  { onSubmit }: ActivationFormProps,
  ref: FormikRef<ActivationFormModel>,
) => (
  <FormikWithRef
    formRef={ref}
    initialValues={initialValues}
    validationSchema={activationSchema}
    onSubmit={onSubmit}
    component={ActivationFormBody}
    validateOnBlur
  />
));
ActivationForm.displayName = 'ActivationForm';
