import React from 'react';
import { HTMLMotionProps, motion } from 'framer-motion';
import { Fab, FabProps } from '@material-ui/core';

interface FABProps extends FabProps<typeof motion.button, HTMLMotionProps<'button'>> {
  label?: string;
  wide?: boolean;
  onClick(): void;
}

export const FAB: React.FC<FABProps> = React.memo(
  function FAB({ onClick, label, wide = false, children, ...props }) {
    return (
      <Fab
        whileTap={{ scale: 0.9 }}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0, opacity: 0 }}
        transition={{ duration: 0.2 }}
        color="primary"
        variant={wide ? 'extended' : 'round'}
        aria-label={label}
        size="medium"
        {...props}
        component={motion.button}
        onClick={onClick}>
        {children}
        {wide && label}
      </Fab>
    );
  },
);
