import { Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { Paths } from '../../paths';

interface LinkActionHref {
  target: Paths;
  text: string;
}

interface LinkActionClick {
  onClick(): void;
  text: string;
}

export type LinkActionDef = LinkActionHref | LinkActionClick;
const isHref = (def: LinkActionDef): def is LinkActionHref => Object.prototype.hasOwnProperty.call(def, 'target');

interface LinkActionProps {
  linkAction?: LinkActionDef;
}

export const LinkAction: React.FC<LinkActionProps> = ({ linkAction }) => {
  if (!linkAction) {
    return null;
  }
  return (
    <Typography align="right" variant="body2">
      {isHref(linkAction) ?
        <Link component={RouterLink} to={linkAction.target} color="secondary">{linkAction.text}</Link> :
        <Link color="secondary" onClick={linkAction.onClick}>{linkAction.text}</Link>
      }
    </Typography>
  );
};
