import React, { forwardRef } from 'react'
import { Field, Form, FormikProps } from 'formik';
import { ResetPasswordFormModel, resetPasswordSchema } from 'models';
import { TextField } from '../../Forms';
import { FormikRef } from '../../../models/types';
import { FormikWithRef } from '../../FormikWithRef';

interface RecoveryFormProps {
  onSubmit(values: ResetPasswordFormModel): Promise<void>;
}

const initialValues: ResetPasswordFormModel = {
  username: '',
  password: '',
  confirmPassword: '',
  code: '',
};

const RecoveryFormBody: React.FC<RecoveryFormProps & FormikProps<ResetPasswordFormModel>> = ({ submitForm }) => (
  <Form onKeyDown={(e) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }}>
    <Field type="code" name="code" placeholder="Activation code" component={TextField} margin="dense" autoFocus />
    <Field type="password" name="password" component={TextField} margin="dense" />
    <Field type="password" name="confirmPassword" component={TextField} margin="dense" label="Confirm password" />
  </Form>
);

export const ResetPasswordForm = forwardRef((
  { onSubmit }: RecoveryFormProps,
  ref: FormikRef<ResetPasswordFormModel>,
) => (
  <FormikWithRef
    formRef={ref}
    initialValues={initialValues}
    validationSchema={resetPasswordSchema}
    onSubmit={onSubmit}
    component={RecoveryFormBody}
    validateOnBlur
  />
));
ResetPasswordForm.displayName = 'ResetPasswordForm';
