import * as yup from 'yup';

export interface ForgotPasswordFormModel {
  email: string;
}

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string()
    .email('Invalid email address')
    .required('This field is required'),
});
