import React from 'react'
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      ...theme.mixins.toolbar,
    },
  }),
);

export const ToolbarSpan: React.FC = () => (
  <div className={useStyles().toolbar} aria-hidden="true" />
);
