import React, { forwardRef, Ref } from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import ContentLoader from "react-content-loader"

const width = 260;
const height = 32;
const tickBox = 18;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'block',
    maxWidth: `${width}px`,
    width: '100%',
    height,
    marginLeft: theme.spacing(6) - 1,
    alignItems: 'center',
  },
  item: {
    marginLeft: theme.spacing(2.5),
  },
}));

export const TodoSkeleton = forwardRef(({ visible }: { visible: boolean }, ref: Ref<HTMLDivElement>) => {
  const classes = useStyles();
  return (
    <div className={classes.root} ref={ref}>
      {visible && (
        <ContentLoader
          viewBox={`0 0 ${width} ${height}`}
          speed={2}
          width={width}
          height={height}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y={(height - tickBox) / 2} rx="2" ry="2" width={tickBox} height={tickBox} />
          <rect x={tickBox + 20} y="9" rx="3" ry="3" width={width - tickBox - 20} height="14" />
        </ContentLoader>
      )}
    </div>
  );
});
TodoSkeleton.displayName = 'TodoSkeleton';
