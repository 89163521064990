import { useAuthManager } from './auth.hook';
import { useCallback } from 'react';
import { useFirebase } from '../firebase';

export const useSignOut = () => {
  const { auth } = useFirebase();
  const { signedOut, startedLoading, errored } = useAuthManager();
  return useCallback(() => {
    async function signOut() {
      startedLoading();
      await auth.signOut();
      signedOut();
    }

    signOut().catch(errored);
  }, [auth, errored, signedOut, startedLoading]);
}
