import { useCallback } from 'react';
import { RegisterFormModel } from '../../models';
import { useAuthManager } from './auth.hook';
import { useFirebase } from '../firebase';
import { gql, useMutation } from '@apollo/client';
import { useWaitForClaims } from './wait-for-claims.hook';
import { createUserWithEmailAndPassword } from '@firebase/auth';

enum SignUpErrorCode {
  EMAIL_IN_USE = 'auth/email-already-in-use',
  INVALID_EMAIL = 'auth/invalid-email',
  NOT_ALLOWED = 'auth/operation-not-allowed',
  WEAK_PASSWORD = 'auth/weak-password',
  INSERT_USER_FAILED = 'auth/insert-user-failed',
}

class SignUpError extends Error {
  constructor(public code: SignUpErrorCode, message?: string) {
    super(message);
  }
}

function handleError(error: SignUpError) {
  switch (error.code) {
    case SignUpErrorCode.EMAIL_IN_USE:
    case SignUpErrorCode.INVALID_EMAIL:
    case SignUpErrorCode.NOT_ALLOWED:
    case SignUpErrorCode.WEAK_PASSWORD:
    case SignUpErrorCode.INSERT_USER_FAILED:
      return error;
  }
}

const upsertUserMutation = gql`
  mutation($objects: [user_insert_input!]!) {
    insert_user(
      objects: $objects,
      on_conflict: { constraint: user_pkey, update_columns: [] }
    ) {
      affected_rows
    }
  }
`;

export const useCredentialsSignup = () => {
  const { auth } = useFirebase();
  const { errored, startedLoading } = useAuthManager();
  const [insertUser] = useMutation(upsertUserMutation);
  const waitForClaims = useWaitForClaims();

  return useCallback((formValues: RegisterFormModel): Promise<any> => {
    startedLoading();

    async function signUp({ email, password, name }: RegisterFormModel) {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      if (!user) {
        throw new SignUpError(SignUpErrorCode.INSERT_USER_FAILED, 'User was not created properly.');
      }
      await waitForClaims(user);
      const token = await user.getIdToken();
      const { uid, displayName } = user;
      await insertUser({
        variables: {
          objects: [{
            firebase_id: uid,
            name: name || displayName || email,
            email,
          }],
        },
        context: {
          headers: { Authorization: `Bearer ${token}` },
        },
      });
    }

    return signUp(formValues)
      .catch((error) => {
        console.error(error);
        handleError(error);
        errored(error);
      });
  }, [auth, insertUser, errored, startedLoading, waitForClaims]);
};
