import React from 'react'
import { ListItemSecondaryAction, ListSubheader, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface SidebarSectionProps {
  title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  subheader: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  }
}));

export const SidebarSection: React.FC<SidebarSectionProps> = ({ title, children }) => {
  const classes = useStyles();
  return (
    <ListSubheader className={classes.subheader}>
      <Typography variant="overline" color="inherit">{title}</Typography>
      <ListItemSecondaryAction>{children}</ListItemSecondaryAction>
    </ListSubheader>
  );
};
