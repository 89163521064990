import React, { ReactElement } from 'react';
import { AppBar, Theme, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { View } from '../PageLayout';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  grow: {
    flexGrow: 1,
  },
}));

interface BottomNavProps {
  view?: View;
  fab?: ReactElement;
  actions?: ReactElement;
}

export const BottomNav: React.FC<BottomNavProps> = React.memo(({ fab, actions }) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" elevation={2} className={classes.appBar}>
      <Toolbar>
        {actions}
        {fab}
        <div className={classes.grow} />
      </Toolbar>
    </AppBar>
  );
});
BottomNav.displayName = 'BottomNav';
