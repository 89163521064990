import * as yup from 'yup';
import { gql } from '@apollo/client';

export enum DateMode {
  NONE = 'NONE',
  REMINDER = 'REMINDER',
  EVENT = 'EVENT',
  ALL_DAY = 'ALL_DAY',
}

type UTCTime = string;
export interface TodoModel {
  id: string;
  title: string;
  children: string[];
  isDone: boolean;
  isPinned: boolean;
  createdAt?: UTCTime;
  updatedAt?: UTCTime;
  startAt: UTCTime;
  endAt: UTCTime;
  parentId: string | null;
  labels: string[];
  comments: string[];
  owner: string;
  projectId: string;
  dateMode: DateMode,
  isExpanded: boolean;
}

export interface TodoFormModel extends Pick<
  TodoModel,
  'title' | 'projectId' | 'dateMode'
> {
  startDate: Date,
  endDate: Date,
}

export const todoFragment = gql`
fragment TodoModel on todo {
  id
  isDone
  isPinned
  parentId
  projectId
  title
  startAt
  endAt
  children
  dateMode
  isExpanded @client
}
`;

export const todoSchema = yup.object().shape({
  title: yup.string().required('Title has to be provided'),
  projectId: yup.string().required('Project must be selected'),
});
export const todosDFS = function dfs(ids: string[], todos: Record<TodoModel['id'], TodoModel>): string[] {
  if (ids.length === 0) {
    return ids;
  }

  const nextIds = ids.flatMap(id => todos[id].children);
  const descendants = todosDFS(nextIds, todos);
  return ids.concat(descendants);
};

export type TodoMap = Record<TodoModel['id'], TodoModel>;
