import React from 'react';
import { AppBar, Dialog, Fade, IconButton, Theme, Toolbar } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';

interface MobileOverlayProps {
  isOpen: boolean;
  onCancel(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
}));

export const FullScreenOverlay: React.FC<MobileOverlayProps> = React.memo(
  function FullScreenOverlay({ isOpen, onCancel, children }) {
    const classes = useStyles();
    return (
      <Dialog open={isOpen} TransitionComponent={Fade} fullScreen disableBackdropClick>
        <AppBar className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onCancel} aria-label="close">
              <ArrowBackIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {children}
      </Dialog>
    );
  },
);
