import { ApolloError, gql, useQuery } from '@apollo/client';
import { todoFragment, TodoModel } from '../../models';
import { NotificationType } from '../../models';
import { useCallback, useEffect, useMemo } from 'react';
import { indexBy, prop } from 'ramda';
import { useNotifications } from '../../core/message-bar';

export const listTodosQuery = gql`
  query ListTodos {
    todos: todo {
      ...TodoModel
    }
  }

  ${todoFragment}
`;

const listTodosSubscription = gql`
  subscription ListTodosSubscription {
    todos: todo {
      ...TodoModel
    }
  }

  ${todoFragment}
`;

export interface ListTodosQueryResult {
  todos: TodoModel[];
}

interface UseListTodosResult {
  loading: boolean;
  error: ApolloError | undefined;
  todos: Record<TodoModel['id'], TodoModel>
}

const defaultData = { todos: [] };
export const useListTodos = (): UseListTodosResult => {
  const {
    data: { todos } = defaultData,
    loading,
    error,
    subscribeToMore,
  } = useQuery<ListTodosQueryResult>(listTodosQuery, { notifyOnNetworkStatusChange: true });
  error && console.error(error);
  const { display } = useNotifications();

  const handleError = useCallback(function handleError(error: Error) {
    console.error(error);
    display({
      type: NotificationType.ERROR,
      message: 'Failed to query/subscribe to list of todos.',
    });
  }, [display]);

  useEffect(() => {
    if (!subscribeToMore) {
      return;
    }

    return subscribeToMore<ListTodosQueryResult>({
      document: listTodosSubscription,
      updateQuery: (prev, next) => next.subscriptionData.data,
      onError: handleError,
    });
  }, [handleError, subscribeToMore]);


  useEffect(() => {
    error && handleError(error);
  }, [handleError, error]);

  return useMemo(() => ({
    loading,
    error,
    todos: indexBy(prop('id'), todos),
  }), [loading, error, todos]);
};
