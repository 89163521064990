import { TodoMap } from '../../models/todos';
import { useListTodos } from './list-todos.hook';
import { useMemo } from 'react';
import { indexBy, prop } from 'ramda';

export const useProjectTodos = (lookupProjectId: string | undefined): TodoMap => {
  const { todos } = useListTodos();
  return useMemo(() => {
    if (!lookupProjectId) {
      return {};
    }
    const todoList = Object
      .values(todos)
      .filter(({ projectId }) => projectId === lookupProjectId);
    return indexBy(prop('id'), todoList);
  }, [todos, lookupProjectId]);
};
