import React, { useRef, useState } from 'react'
import { IconButton, ListItemSecondaryAction, Menu } from '@material-ui/core';
import MoreVertTwoTone from '@material-ui/icons/MoreVertTwoTone';
import { withStyles } from '@material-ui/styles';
import { MenuProps } from '@material-ui/core/Menu';
import { ProjectDeleteOpt } from './ProjectDeleteOpt';

interface ProjectOptsProps {
  title: string;
  id: string;
}

const StyledMenu = withStyles({
  paper: { border: '1px solid #d3d4d5' },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    {...props}
  />
));

export const ProjectOpts: React.FC<ProjectOptsProps> = ({ title, id }) => {
  const anchor = useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);
  return (
    <>
      <ListItemSecondaryAction>
        <IconButton
          ref={anchor}
          aria-label={`Options for project ${title}`}
          aria-controls="project-opts"
          aria-haspopup="true"
          onClick={toggleMenu}>
          <MoreVertTwoTone />
        </IconButton>
      </ListItemSecondaryAction>
      <StyledMenu id="projects-opts" anchorEl={anchor.current} open={showMenu} onClose={toggleMenu} keepMounted>
        <div><ProjectDeleteOpt id={id} onDone={toggleMenu}/></div>
      </StyledMenu>
    </>
  );
};
