import React, { ReactElement } from 'react';
import { Container, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export interface SingleColumnProps {
  header: ReactElement;
}

export const SingleColumn: React.FC<SingleColumnProps> = ({ children, header }) => {
  const classes = useStyles();
  if (!children) {
    return null;
  }

  return (
    <>
      {header}
      <Container className={classes.root}>
        {children}
      </Container>
    </>
  );
};
