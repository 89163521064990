import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Drawer, Theme } from '@material-ui/core';
import { ToolbarSpan } from './ToolbarSpan';
import { RailbarButton } from './RailbarButton';
import { noop } from '../../utils/noop';
import TodosIcon from '@material-ui/icons/CheckBoxTwoTone';
import NotesIcon from '@material-ui/icons/BookTwoTone';
import MessagingIcon from '@material-ui/icons/QuestionAnswerTwoTone';
import CalendarIcon from '@material-ui/icons/CalendarTodayTwoTone';

const useStyles = makeStyles((theme: Theme) => createStyles({
  railbar: {
    width: 80,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  railbarPaper: {
    width: 80,
    background: theme.palette.common.white,
    paddingTop: theme.spacing(1),
  },
}));

export const Railbar: React.FC = () => {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.railbar}
      variant="persistent"
      anchor="left"
      open={true}
      classes={{ paper: classes.railbarPaper }}>
      <ToolbarSpan />
      <RailbarButton icon={<TodosIcon />} caption="Todos" onClick={noop} />
      <RailbarButton icon={<NotesIcon />} caption="Notes" onClick={noop} disabled />
      <RailbarButton icon={<MessagingIcon />} caption="Messaging" onClick={noop} disabled />
      <RailbarButton icon={<CalendarIcon />} caption="Calendar" onClick={noop} disabled />
    </Drawer>
  );
};
