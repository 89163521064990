import React, { useState } from 'react';
import { Drawer, IconButton, Theme } from '@material-ui/core';
import AllInbox from '@material-ui/icons/AllInbox';
import { ProjectList } from './ProjectList';
import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import { ExpandIcon } from '../ExpandIcon';

const useStyles = makeStyles((theme: Theme) => ({
  projectDrawerPaper: {
    bottom: theme.mixins.toolbar.height,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
    zIndex: theme.zIndex.modal + 3,
  },
}));

export const ProjectListDrawer: React.FC = () => {
  const [projectsDrawerOpen, setProjectsDrawerOpen] = useState(false);
  const toggleProjectsDrawer = () => setProjectsDrawerOpen(!projectsDrawerOpen);
  const classes = useStyles();
  return (
    <motion.div
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <IconButton edge="start" color="inherit" aria-label="Show/hide project list" onClick={toggleProjectsDrawer}>
        <AllInbox />
        <ExpandIcon open={projectsDrawerOpen} />
      </IconButton>
      <Drawer
        anchor="bottom"
        open={projectsDrawerOpen}
        onClose={toggleProjectsDrawer}
        classes={{
          paperAnchorBottom: classes.projectDrawerPaper,
        }}
      >
        <ProjectList onSelect={toggleProjectsDrawer} />
      </Drawer>
    </motion.div>
  );
};
