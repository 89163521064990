import React from 'react';
import { Typography } from '@material-ui/core';
import { ProjectIcon } from './ProjectIcon';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';

interface ProjectTitleProps {
  className?: string;
  classes?: {
    root?: string;
    title?: string;
  };
  hideIcon?: boolean;
  color: string;
  title: string;
  loading?: boolean;
}

export const ProjectTitle: React.FC<ProjectTitleProps> = ({
  className,
  classes = {},
  hideIcon = false,
  title,
  color,
  loading,
}) => {
  return (
    <Typography
      variant="h6"
      className={classNames(className, classes.root)}
      style={{ color, display: 'flex' }}
    >
      {loading ?
        <Skeleton variant="rect" width={120} height={20} /> :
        <>
          {!hideIcon && <ProjectIcon color={color} selected={true} />}
          <span className={classes.title}>{title}</span>
        </>
      }
    </Typography>
  );
};
