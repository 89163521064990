import React, { useCallback } from 'react'
import { ProjectOptsItem } from './ProjectOptsItem';
import Delete from '@material-ui/icons/Delete';
import { useDeleteProject } from '../../../hooks/projects/delete-project.hook';

interface ProjectDeleteOptProps {
  id: string;
  onDone(): void;
}

// TODO If deleted project is currently selected, select a different one
export const ProjectDeleteOpt: React.FC<ProjectDeleteOptProps> = ({ onDone, id }) => {
  const { deleteProject, loading } = useDeleteProject();
  const performDelete = useCallback(
    () => {
      deleteProject(id).then(onDone)
    },
    [id, deleteProject, onDone],
  );
  return (
    <ProjectOptsItem title="Delete" icon={<Delete />} onClick={performDelete} loading={loading} />
  );
};
