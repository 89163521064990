import { useFirebase } from '../firebase';
import { useCallback } from 'react';
import { LoginFormModel } from '../../models';
import { useAuthManager } from './auth.hook';
import { browserLocalPersistence, setPersistence, signInWithEmailAndPassword } from '@firebase/auth';

enum SignInErrorCode {
  INVALID_EMAIL = 'auth/invalid-email',
  USER_DISABLED = 'auth/user-disabled',
  NOT_FOUND = 'auth/user-not-found',
  WRONG_PASSWORD = 'auth/wrong-password',
}

type SignInError = Error & { code: SignInErrorCode };

function handleError(error: SignInError) {
  switch (error.code) {
    case SignInErrorCode.INVALID_EMAIL:
    case SignInErrorCode.NOT_FOUND:
    case SignInErrorCode.USER_DISABLED:
    case SignInErrorCode.WRONG_PASSWORD:
      return error;
  }
}

type SignInCallback = (form: LoginFormModel) => Promise<void>;

export const useCredentialsLogin = (): SignInCallback => {
  const { auth } = useFirebase();
  const { errored, startedLoading } = useAuthManager();
  return useCallback(async function signIn({ email, password }: LoginFormModel): Promise<void> {
    startedLoading();
    await setPersistence(auth, browserLocalPersistence);
    await signInWithEmailAndPassword(auth, email, password)
      .catch((error) => errored(handleError(error)));
  }, [auth, errored, startedLoading]);
};
