import React, { useCallback, useState } from 'react';
import { Button, IconButton, List, ListItem, ListItemIcon, ListItemText, Theme } from '@material-ui/core';
import AddBoxTwoTone from '@material-ui/icons/AddBoxTwoTone';
import Done from '@material-ui/icons/Done';
import SettingsApplicationsTwoTone from '@material-ui/icons/SettingsApplicationsTwoTone';
import { makeStyles } from '@material-ui/styles';
import { ProjectListItem } from './ProjectListItem';
import { EmptyProjectList } from './EmptyProjectList';
import { ProjectModel } from '../../models';
import { SidebarSection } from '../Sidebar/SidebarSection';
import { Loading } from '../Loading';
import { DisplayIf } from '../DisplayIf';
import { CreateProjectModal } from './CreateProjectModal';
import { ToolbarSpan } from '../Sidebar/ToolbarSpan';
import { useListProjects } from '../../hooks/projects';
import { selectedProjectVar } from '../../core/apollo';

const useStyles = makeStyles((theme: Theme) => ({
  bg200: {
    color: (theme.palette.primary as any)[200],
  },
  root: {
    display: 'block',
    overflow: 'auto',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxHeight: '65vh',
    },
  },
}));

interface ProjectListProps {
  onSelect?(): void;
}

export const ProjectList: React.FC<ProjectListProps> = ({ onSelect }) => {
  const classes = useStyles();
  const { projects, loading, error, isEmpty } = useListProjects();
  const [isManaging, setManage] = useState(false);
  const [isCreating, setCreating] = useState(false);
  const toggleManage = () => setManage(!isManaging);
  const toggleCreating = () => setCreating(!isCreating);

  const onSelectProject = useCallback((id: string) => {
    selectedProjectVar(id);
    onSelect && onSelect();
  }, [onSelect]);

  const subheader = (
    <SidebarSection title="Projects">
      <IconButton size="small" onClick={toggleManage} aria-label="Manage projects">
        <SettingsApplicationsTwoTone className={classes.bg200} />
      </IconButton>
      <IconButton size="small" onClick={toggleCreating} aria-label="Create new project">
        <AddBoxTwoTone className={classes.bg200} />
      </IconButton>
    </SidebarSection>
  );

  if (isEmpty) {
    return (
      <Loading isLoading={loading}>
        <List className={classes.root} disablePadding subheader={subheader}>
          <EmptyProjectList />
        </List>
      </Loading>
    );
  }
  return (
      <List className={classes.root} disablePadding subheader={subheader} data-testid="project-list">
        <Loading isLoading={loading}>
          <DisplayIf expr={!error} onElse={<span>Error!</span>}>
            {
              projects.map((project: ProjectModel) => (
                <ProjectListItem
                  key={project.id}
                  project={project}
                  isManaging={isManaging}
                  isSelected={project.is_selected}
                  onSelect={onSelectProject}
                />
              ))
            }
          </DisplayIf>
        </Loading>
        <DisplayIf expr={isManaging}>
          <ListItem component={Button} onClick={toggleManage}>
            <ListItemIcon><Done color="primary" /></ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: 'primary' }}>Done</ListItemText>
          </ListItem>
        </DisplayIf>
        <CreateProjectModal open={isCreating} onClose={toggleCreating} />
        <ToolbarSpan />
      </List>
  );
};
