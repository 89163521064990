import React, { useCallback, useMemo } from 'react';
import { Button, CardActions, FormGroup, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TextField } from 'formik-material-ui';
import { Field, Form, FormikHelpers, FormikProps } from 'formik';
import classNames from 'classnames';
import { View } from '../../PageLayout/layout-manager.hook';
import { useLayout } from '../../PageLayout/layout.hook';
import { DateMode, TodoFormModel, todoSchema } from '../../../models/todos';
import { ProjectModel } from '../../../models';
import { NoResources } from '../../NoResources';
import { ProjectSelect } from '../../Forms/ProjectSelect';
import { FormikWithRef } from '../../FormikWithRef';
import { FormikRef } from '../../../models/types';
import { DateModePicker, DateTimePicker } from './Fields';
import { AddDateButton } from './Fields/AddDateButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingRight: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1, 0),
    },
  },
  newTodo: {
    fontSize: theme.typography.h5.fontSize,
  },
  center: {
    justifyContent: 'center',
  },
  noIcon: {
    marginLeft: theme.spacing(7),
  },
  actions: {
    flexGrow: 1,
  },
}));

export interface TodoFormProps {
  onSubmit(values: TodoFormModel, formik: FormikHelpers<TodoFormModel>): Promise<void>;
  formRef?: FormikRef<TodoFormModel>;
  projectId: ProjectModel['id'];
  projects: ProjectModel[];
}

export interface TodoFormBodyProps {
  standalone?: boolean;
  projects: ProjectModel[];
}

export const TodoFormBody: React.FC<TodoFormBodyProps & FormikProps<TodoFormModel>> = ({
  submitForm,
  projects,
  errors,
  values: { projectId, dateMode },
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { setCurrentView, toggleSideSheet } = useLayout();

  const handleSubmit = useCallback(async () => {
    await submitForm();
    if (errors) {
      return;
    }
    toggleSideSheet(false);
    setCurrentView(View.TODO_LIST);
  }, [setCurrentView, submitForm, toggleSideSheet, errors]);

  const classes = useStyles();

  if (projects.length === 0) {
    return (
      <Typography color="textSecondary" component="span">
        <NoResources
          title="Missing projects">
          You need to create a project first
        </NoResources>
      </Typography>
    );
  }

  return (
    <>
      <Form className={classes.root} autoComplete="off">
        <Field
          id="todo-form_title"
          component={TextField}
          type="text"
          name="title"
          placeholder="What do you plan?"
          inputProps={{
            'aria-label': 'Title of the new todo',
            className: classes.newTodo,
          }}
          className={classNames(classes.noIcon)}
          autoComplete="off"
          autoFocus
        />
        <ProjectSelect projects={projects} />
        {dateMode === DateMode.NONE && <AddDateButton />}
        {dateMode !== DateMode.NONE && (
          <DateTimePicker name="startDate" withTime={dateMode !== DateMode.ALL_DAY} />
        )}
        {dateMode === DateMode.EVENT && <DateTimePicker name="endDate" withTime />}
        {dateMode !== DateMode.NONE && <DateModePicker />}
        <FormGroup className={classNames(classes.noIcon)}>
          <Typography variant="caption" color="textSecondary">
            Preferences
          </Typography>
          <Typography align="left" color="textSecondary">
            No additional preferences were set
          </Typography>
        </FormGroup>
      </Form>
      {isDesktop && <CardActions>
        <div className={classes.actions} />
        <Button color="primary" onClick={handleSubmit}>Create</Button>
      </CardActions>
      }
    </>
  );
};

export const TodoForm = React.memo(
  function TodoForm(
    { onSubmit, projects, formRef, projectId }: TodoFormProps,
  ) {
    const defaultValues = useMemo((): TodoFormModel => ({
      dateMode: DateMode.NONE,
      title: '',
      projectId,
      startDate: new Date(),
      endDate: new Date(),
    }), [projectId]);

    return (
      <FormikWithRef<TodoFormModel>
        formRef={formRef}
        initialValues={defaultValues}
        validationSchema={todoSchema}
        onSubmit={onSubmit}
        validateOnMount
        enableReinitialize
      >
        {(formikProps) => <TodoFormBody {...formikProps} standalone={!formRef} projects={projects} />}
      </FormikWithRef>
    );
  },
);
