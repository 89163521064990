import { InMemoryCache, makeVar } from '@apollo/client';
import { NotificationModel, ProjectModel, TodoModel } from '../../models';
import { getByPkFieldPolicy } from './type-policies';

export const selectedProjectVar = makeVar<ProjectModel['id'] | undefined>(undefined);
export const expandedTodosVar = makeVar<Record<TodoModel['id'], boolean>>({});
export const notificationsVar = makeVar<NotificationModel[]>([]);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        project_by_pk: getByPkFieldPolicy<ProjectModel>('project'),
        notifications: {
          read: (): NotificationModel[] => notificationsVar(),
        },
      },
    },
    todo: {
      keyFields: ['id'],
      fields: {
        children: {
          merge: (existing: string[], incoming: string[]) => incoming,
        },
        isExpanded: {
          read: (_, { readField }) => {
            const id: string | undefined = readField('id');
            const expanded = expandedTodosVar();
            return id ? !!expanded[id] : false;
          },
        },
      },
    },
    project: {
      keyFields: ['id'],
      fields: {
        todos_order: {
          merge: (existing: string[], incoming: string[]) => incoming,
        },
        is_selected: {
          read: (_, { readField }) => {
            const id = readField('id');
            return id === selectedProjectVar();
          },
        },
      },
    },
  },
});
