import React, { useCallback } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { AuthStatus, useAuthManager } from '../auth.hook';
import { Paths } from '../../../paths';
import { SplashScreen } from '../../../components/SplashScreen';

export const PrivateRoute: React.FC<RouteProps> = React.memo(
  function PrivateRoute({ children, ...rest }) {
    const { status } = useAuthManager();
    const render = useCallback(({ location }) => {
      if (status === AuthStatus.LOADING) {
        return <SplashScreen />;
      }
      if (status === AuthStatus.SIGNED_IN) {
        return children;
      }
      return <Redirect to={{ pathname: Paths.LOGIN, state: { from: location } }} />;
    }, [status, children]);
    return (
      <Route {...rest} render={render} />
    );
  },
);
