import React, { MouseEvent } from 'react';
import { ButtonBase, fade, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

interface RailbarButtonProps {
  icon: JSX.Element;
  caption: string;
  onClick(evt: MouseEvent<HTMLButtonElement>): void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  railbarButton: {
    // margin: theme.spacing(1),
    display: 'block',
    borderRadius: '50%',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: fade(theme.palette.action.active, theme.palette.action.hoverOpacity),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  railbarButtonImg: {
    display: 'block',
    width: 32,
    height: 32,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  railbarButtonText: {
    display: 'block',
    color: theme.palette.primary.light,
  },
}));

export const RailbarButton: React.FC<RailbarButtonProps> = ({ icon, caption, onClick, disabled }) => {
  const classes = useStyles();
  return (
    <ButtonBase
      focusRipple
      color="primary"
      aria-label={caption}
      onClick={onClick}
      disabled={disabled}
      className={classes.railbarButton}>
      <Typography variant="overline" className={classes.railbarButtonText}>
        {React.cloneElement(icon, { className: classes.railbarButtonImg })}
        {caption}
      </Typography>
    </ButtonBase>
  );
};
