import { useFirebase } from '../core/firebase';
import { useEffect, useState } from 'react';

export const useConnectionState = (): boolean => {
  const { database } = useFirebase();
  const [isOnline, setState] = useState(true);
  useEffect(() => {
    const connectedRef = database.ref('.info/connected');
    const handler = connectedRef.on('value', (snap) => {
      setState(snap.val());
    });
    return connectedRef.off('value', handler);
  }, [database]);
  return isOnline;
}
