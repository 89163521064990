import React, { PropsWithChildren, useCallback, useImperativeHandle, useRef } from 'react';
import { Formik, FormikConfig, FormikProps } from 'formik';
import { FormikRef } from '../models/types';

interface FormikWithRefProps<Values extends object> extends FormikConfig<Values> {
  formRef?: FormikRef<Values>;
}

export function FormikWithRef<Values extends object>(
  { formRef, ...props }: PropsWithChildren<FormikWithRefProps<Values>>,
) {
  const objRef = useRef<FormikProps<Values>>();
  const innerRef = useCallback((formikBag: FormikProps<Values>) => {
    objRef.current = formikBag;
  }, []);
  useImperativeHandle(formRef, () => objRef.current);
  return <Formik innerRef={innerRef} {...props} />;
}
