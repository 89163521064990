import React, { useCallback, useEffect, useRef } from 'react';
import { FormikHelpers } from 'formik';
import { Hidden, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SendIcon from '@material-ui/icons/Send';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import AssignmentTurnedInTwoToneIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';
import { TodoList } from '../TodoList';
import { useListProjects } from '../../hooks/projects';
import { useCreateTodo, useListTodos } from '../../hooks/todos';
import { ProjectModel, TodoFormModel, TodoModel } from '../../models';
import { NoResources } from '../NoResources';
import { Overlay, useOverlay } from '../../hooks/overlay.hook';
import { CreateTodoOverlay, TodoForm } from './CreateTodoView';
import { ProjectListDrawer, ProjectTitle } from '../ProjectList';
import { SidebarLayout, SideSheet, useLayout, View, withLayoutProvider } from '../PageLayout';
import { Header } from '../../layout/components/Header';
import { AppNav } from '../AppNav';
import { SignOutButton } from '../AuthView/SignOutButton';
import { CreateTodoFAB } from './CreateTodoFAB';
import { FormikRef } from '../../models/types';
import { TodoFormModifiers } from './CreateTodoView/TodoFormModifiers';
import { FAB } from '../FAB';
import { ViewPicker } from 'components/ViewPicker';
import { AnimatePresence } from 'framer-motion';
import { Loading } from '../Loading';
import { useProjectTodos } from '../../hooks/todos/project-todos.hook';
import { prop } from 'ramda';

interface TodosViewBodyProps {
  project: ProjectModel | undefined;
  todos: Record<TodoModel['id'], TodoModel>;
  onCreateTodo(): void;
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  listHeader: {
    padding: theme.spacing(2, 2, 0, 4.5),
  },
  title: {
    paddingLeft: theme.spacing(1),
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    right: theme.spacing(2),
  },
  footerButtons: {
    color: theme.palette.primary.main,
    '& > *:not(:last-of-type)': {
      marginRight: theme.spacing(1),
    },
  },
}));

export const TodosViewBody: React.FC<TodosViewBodyProps> = React.memo(
  function TodosViewBody({ todos, project, onCreateTodo, loading = false }) {
    const classes = useStyles();
    const { setCurrentView } = useLayout();

    useEffect(() => {
      setCurrentView(View.TODO_LIST);
    }, [setCurrentView]);

    if (!project) {
      return (
        <Loading isLoading={loading}>
          <Typography color="textSecondary" component="span">
            <NoResources title="The selected project doesn't exist" icon={WarningTwoToneIcon} />
          </Typography>
        </Loading>
      );
    }
    if (Object.keys(todos).length === 0) {
      return (
        <Typography color="textSecondary" component="span">
          <NoResources
            title="No tasks on this list"
            callToAction={{ call: 'Create todo', action: onCreateTodo }}
            icon={AssignmentTurnedInTwoToneIcon}
          >
            But the journey doesn&apos;t stop here
          </NoResources>
        </Typography>
      );
    }

    return (
      <>
        <Hidden smDown>
          <ProjectTitle
            className={classes.listHeader}
            classes={{ title: classes.title }}
            title={project.title}
            color={project.color}
          />
        </Hidden>
        <TodoList
          todos={todos}
          todosOrder={project.todos_order}
          nestingLevel={1}
          parentId={project.id}
          allParentsIds={[]}
        />
      </>
    );
  },
);

export const TodosView = withLayoutProvider()(function TodosView() {
  const { loading: projectsLoading, projects } = useListProjects();
  const { loading: todosLoading } = useListTodos();
  const selectedProject: ProjectModel | undefined = projects.find(prop('is_selected'));
  const loading = projectsLoading || todosLoading;
  const todos: Record<TodoModel['id'], TodoModel> = useProjectTodos(selectedProject?.id);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { toggleSideSheet, setCurrentView, currentView } = useLayout();
  const [currentOverlay, setOverlay] = useOverlay();
  const isTodoOverlayOpen = currentOverlay === Overlay.CREATE_TODO_OVERLAY;
  const { insertTodo } = useCreateTodo();
  const classes = useStyles();
  const formRef: FormikRef<TodoFormModel> = useRef();

  const handleSubmit = useCallback(async (values: TodoFormModel, { resetForm }: FormikHelpers<any>) => {
    await insertTodo(values);
    setOverlay(Overlay.UNSET);
    setCurrentView(View.TODO_LIST);
    resetForm();
  }, [setCurrentView, insertTodo, setOverlay]);

  const submitForm = useCallback(() => formRef.current?.submitForm(), [formRef]);

  const createTodo = useCallback(() => {
    if (isDesktop) {
      toggleSideSheet();
    } else {
      setOverlay(currentOverlay === Overlay.CREATE_TODO_OVERLAY ? Overlay.UNSET : Overlay.CREATE_TODO_OVERLAY);
      setCurrentView(View.TODO_LIST);
    }
  }, [setCurrentView, toggleSideSheet, currentOverlay, setOverlay, isDesktop]);

  const header = (
    <Header title={selectedProject?.title || 'NoTodos'} navigation={<AppNav />} displayIcon={isDesktop}>
      <SignOutButton />
    </Header>
  );

  const fab = (
    <AnimatePresence exitBeforeEnter>
      <ViewPicker.Switch currentView={currentView}>
        <span key={currentView}>
          <ViewPicker.Case viewId={View.TODO_LIST} component={CreateTodoFAB} />
          <ViewPicker.Case viewId={View.CREATE_TODO}>
            <FAB
              aria-label="Create todo"
              className={classes.fabButton}
              onClick={submitForm}
            >
              <SendIcon />
            </FAB>
          </ViewPicker.Case>
        </span>
      </ViewPicker.Switch>
    </AnimatePresence>
  );

  const actions = (
    <AnimatePresence exitBeforeEnter>
      <ViewPicker.Switch currentView={currentView}>
        <span key={currentView}>
          <ViewPicker.Case viewId={View.TODO_LIST} component={ProjectListDrawer} />
          <ViewPicker.Case viewId={View.CREATE_TODO} component={TodoFormModifiers} className={classes.footerButtons} />
        </span>
      </ViewPicker.Switch>
    </AnimatePresence>
  );

  if (!selectedProject) {
    return (
      <SidebarLayout loading={loading} header={header}>
        <Typography color="textSecondary" component="span">
          <NoResources title="No project selected">
            Select a project to display todos
          </NoResources>
        </Typography>
      </SidebarLayout>
    );
  }

  const onCancel = () => {
    setOverlay(Overlay.UNSET);
    setCurrentView(View.TODO_LIST);
  };

  return (
    <SidebarLayout header={header} view={View.TODO_LIST} fab={fab} actions={actions}>
      <TodosViewBody project={selectedProject} todos={todos} onCreateTodo={createTodo} loading={loading} />
      { isDesktop ?
        <SideSheet header="Create a todo" onClose={toggleSideSheet}>
          <TodoForm onSubmit={handleSubmit} projects={projects} formRef={formRef} projectId={selectedProject.id} />
        </SideSheet> :
        <CreateTodoOverlay isOpen={isTodoOverlayOpen} onCancel={onCancel}>
          <TodoForm onSubmit={handleSubmit} projects={projects} formRef={formRef} projectId={selectedProject.id} />
        </CreateTodoOverlay>
      }
    </SidebarLayout>
  );
});
