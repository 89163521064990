import { useContext } from 'react';
import { LayoutManagerContext, LayoutSpec } from './layout-manager.hook';

export const useLayout = (): LayoutSpec => {
  const manager = useContext(LayoutManagerContext);
  if (!manager) {
    throw new Error('The `useLayout` hook can only be used within `LayoutProvider`.');
  }
  return manager;
}
