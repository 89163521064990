import React, { forwardRef } from 'react'
import { Field, Form, FormikProps } from 'formik';
import { TextField } from '../../Forms';
import { RegisterFormModel, registerSchema } from '../../../models';
import { FormikRef } from '../../../models/types';
import { FormikWithRef } from '../../FormikWithRef';

interface RegisterFormProps {
  onSubmit(values: RegisterFormModel): Promise<void>;
}

const initialValues: RegisterFormModel = {
  email: '',
  name: '',
  password: '',
  confirmPassword: '',
};

const RegisterFormBody: React.FC<RegisterFormProps & FormikProps<RegisterFormModel>> = ({ submitForm }) => (
  <Form onKeyDown={(e) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }}>
    <Field type="email" name="email" component={TextField} margin="dense" autoFocus />
    <Field type="text" name="name" component={TextField} margin="dense" />
    <Field type="password" name="password" component={TextField} margin="dense" />
    <Field type="password" name="confirmPassword" component={TextField} margin="dense" label="Confirm password" />
  </Form>
);

export const RegisterForm = forwardRef((
  { onSubmit }: RegisterFormProps,
  ref: FormikRef<RegisterFormModel>,
) => (
  <FormikWithRef
    formRef={ref}
    initialValues={initialValues}
    validationSchema={registerSchema}
    validateOnBlur
    onSubmit={onSubmit}
    component={RegisterFormBody}
  />
));
RegisterForm.displayName = 'RegisterForm';
