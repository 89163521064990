import { makeVar } from '@apollo/client';

export enum NotificationType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

export interface NotificationModel {
  id: string;
  type: NotificationType;
  message: string;
}
export type NotificationMap = Record<NotificationModel['id'], NotificationModel>;

export const notifications = makeVar<Record<NotificationModel['id'], NotificationModel>>({});
