import React from 'react';
import { LoginForm } from './LoginForm';
import { LoginFormModel } from '../../../models';
import { AuthCard } from '../AuthCard';
import { Paths } from '../../../paths';

interface LoginCardProps {
  onLogin(formValues: LoginFormModel): Promise<void>;
}

export const LoginCard: React.FC<LoginCardProps> = ({ onLogin }) => (
  <AuthCard
    onSubmit={onLogin}
    formComponent={LoginForm}
    title="Sign in"
    submitText="Sign in"
    alternativeAction={{
      targetPath: Paths.REGISTER,
      actionText: 'Sign up',
    }}
    linkAction={{
      target: Paths.FORGOT_PASSWORD,
      text: 'Forgot password?',
    }}
  />
);
