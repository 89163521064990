import { gql, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { NotificationModel } from '../../models';
import { notificationsVar } from '../apollo';
import { v4 as uuid } from 'uuid';

export const getNotificationsQuery = gql`
  query GetMessages {
    notifications @client {
      id
      type
      message
    }
  }
`;

export interface NotificationsQueryResult {
  notifications: NotificationModel[];
}

export interface UseNotificationsResult {
  notifications: NotificationModel[];
  display: (prototype: Omit<NotificationModel, 'id'>) => void;
  dismiss: (id: NotificationModel['id']) => void;
}

export const useNotifications = (): UseNotificationsResult => {
  const { data: { notifications } = { notifications: [] } } = useQuery<NotificationsQueryResult>(getNotificationsQuery);
  const dismiss = useCallback((lookupId: string) => {
    const filtered = notifications.filter(({ id }) => id === lookupId);
    notificationsVar(filtered);
  }, [notifications]);
  const display = useCallback((prototype: Omit<NotificationModel, 'id'>) => {
    const notification: NotificationModel = { ...prototype, id: uuid() };
    notificationsVar([...notifications, notification]);
  }, [notifications]);
  return useMemo(() => ({ notifications, display, dismiss }), [notifications, display, dismiss]);
};
