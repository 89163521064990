import React from 'react';
import { ResetPasswordFormModel } from '../../../models';
import { RedirectUser } from '../RedirectUser';
import { SingleColumn } from '../../PageLayout';
import { ResetPasswordCard } from './ResetPasswordCard';
import { useParams } from 'react-router';
import { Header } from '../../../layout/components/Header';
import { SignInButton } from '../SignInButton';
import { SignUpButton } from '../SignUpButton';
import { noop } from '../../../utils/noop';

export const ResetPasswordView: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const onActivate = (formValues: ResetPasswordFormModel) => {
    console.log(formValues, username);
    return Promise.resolve();
  };
  const header = (
    <Header title="NoTodos">
      <SignInButton />
      <SignUpButton />
    </Header>
  );

  return (
    <RedirectUser to="/todos">
      <SingleColumn header={header}>
        <ResetPasswordCard
          onActivate={onActivate}
          onResendCode={noop}
        />
      </SingleColumn>
    </RedirectUser>
  );
};
