import React from 'react';
import { Theme, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLayout } from '../layout.hook';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexBasis: '25vw',
    flexShrink: 0,
  },
  paper: {
    width: '25vw',
  },
  header: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start',
  },
}));

interface SideSheetProps {
  header?: string;
  onClose(): void;
}

export const SideSheet: React.FC<SideSheetProps> = ({ header, children, onClose }) => {
  const classes = useStyles();
  const { isSideSheetOpen } = useLayout();
  return (
    <Drawer
      variant="persistent"
      classes={{ root: classes.root, paper: classes.paper }}
      anchor="right"
      open={isSideSheetOpen}
    >
      <Toolbar />
      <Typography variant="subtitle1" className={classes.header}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {header}
      </Typography>
      {children}
    </Drawer>
  )
}
