import React from 'react';
import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import RepeatIcon from '@material-ui/icons/Repeat';
import LocalOfferTwoToneIcon from '@material-ui/icons/LocalOfferTwoTone';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone';
import { WithClassName } from '../../../models/types';
import { motion } from 'framer-motion';

export const TodoFormModifiers: React.FC<WithClassName> = ({ className }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  if (isDesktop) {
    return null;
  }

  return (
    <motion.div
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0, opacity: 0 }}
      transition={{ duration: 0.2 }}
      className={className}>
      <IconButton size="small" color="inherit">
        <RepeatIcon />
      </IconButton>
      <IconButton size="small" color="inherit">
        <LocalOfferTwoToneIcon />
      </IconButton>
      <IconButton size="small" color="inherit">
        <NotificationsActiveTwoToneIcon />
      </IconButton>
      <IconButton size="small" color="inherit">
        <LocationOnTwoToneIcon />
      </IconButton>
    </motion.div>
  );
};
