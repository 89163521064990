import React, { MouseEvent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, ListItem, ListItemIcon, ListItemText, Theme } from '@material-ui/core';

interface ProjectOptsItemProps {
  icon: React.ReactElement;
  title: string;
  loading: boolean;
  onClick(evt: MouseEvent<HTMLDivElement>): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
  wrapper: {
    margin: theme.spacing(0),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const ProjectOptsItem: React.FC<ProjectOptsItemProps> = ({ icon, title, onClick, loading }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <ListItem className={classes.btn} onClick={onClick} disabled={loading} button>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
          {loading && <CircularProgress size={24} color="secondary" className={classes.buttonProgress} />}
        </ListItem>
      </div>
    </div>
  );
};
