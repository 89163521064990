import React from 'react';
import { Button } from '@material-ui/core';
import { useSignOut } from '../../core/auth/signout.hook';

interface SignOutButtonProps {
  className?: string;
}

export const SignOutButton: React.FC<SignOutButtonProps> = ({ className }: SignOutButtonProps) => {
  const onSignOut = useSignOut();
  return (
    <Button
      onClick={onSignOut}
      variant="outlined"
      className={className}
      id="sign-out-btn"
    >
      Sign out
    </Button>
  );
};
