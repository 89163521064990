import { gql, useMutation } from '@apollo/client';
import { NotificationType } from '../../models';
import { useCallback } from 'react';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { useNotifications } from '../../core/message-bar';

const deleteProjectMutation = gql`
  mutation DeleteProject($id: uuid!) {
    project: delete_project_by_pk(id: $id) {
      id
    }
  }
`;

interface QueryData {
  project: { id: string; };
}

export const useDeleteProject = (options?: MutationHookOptions<QueryData>) => {
  const [deleteProject, result] = useMutation<QueryData>(deleteProjectMutation, options);
  const { error } = result;
  const { display } = useNotifications();

  const onFailure = useCallback(() => display({
    type: NotificationType.ERROR,
    message: 'Failed to delete the project',
  }), [display]);

  const performMutation = useCallback((id: string) => {
    return deleteProject({ variables: { id } })
      .catch((error) => {
        console.error(error);
        onFailure();
      });
  }, [deleteProject, onFailure]);

  if (error) {
    onFailure();
  }

  return {
    deleteProject: performMutation,
    ...result,
  };
}
