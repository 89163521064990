import React from 'react';
import { ResetPasswordFormModel } from '../../../models';
import { AuthCard } from '../AuthCard';
import { ResetPasswordForm } from './ResetPasswordForm';
import VerifiedUserTwoTone from '@material-ui/icons/VerifiedUserTwoTone';

interface RecoveryCardProps {
  onActivate(formValues: ResetPasswordFormModel): Promise<void>;
  onResendCode(): void;
}

export const ResetPasswordCard: React.FC<RecoveryCardProps> = ({ onActivate, onResendCode }) => (
  <AuthCard
    onSubmit={onActivate}
    formComponent={ResetPasswordForm}
    title="Forgotten password?"
    submitText="Verify"
    info="Please provide below the activation code that was sent to you and a new password:"
    iconComponent={VerifiedUserTwoTone}
    linkAction={{
      onClick: onResendCode,
      text: 'Resend code?',
    }}
  />
);
