import React from 'react';
import { List, Theme } from '@material-ui/core';
import { TodoItem } from './TodoItem';
import { makeStyles } from '@material-ui/styles';
import { TodoEdge } from './TodoEdge';
import { DnDMoveType, TodoModel } from '../../models/todos';
import { motion } from 'framer-motion';
import { ProjectModel } from '../../models';

interface TodoListProps {
  todos: Record<TodoModel['id'], TodoModel>;
  todosOrder: Array<TodoModel['id']>;
  nestingLevel: number;
  parentId: ProjectModel['id'] | TodoModel['id'];
  allParentsIds: Array<TodoModel['id']>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: ({ nesting }: { nesting: number }) => theme.spacing(nesting > 1 ? 2 : 0),
    userSelect: 'none',
  },
}));

const spring = {
  type: 'spring',
  damping: 20,
  stiffness: 300,
};

export const TodoList: React.FC<TodoListProps> = ({ todos, todosOrder, nestingLevel = 1, parentId, allParentsIds }) => {
  const classes = useStyles({ nesting: nestingLevel });
  const isRoot = nestingLevel === 1;
  const firstTodo = todos[todosOrder[0]];
  if (!firstTodo) {
    return null;
  }
  return (
    <List component="div" dense className={classes.root} disablePadding={nestingLevel > 1}>
      <TodoEdge
        type={DnDMoveType.BEFORE}
        todo={firstTodo}
        disabledIds={[firstTodo.id, ...allParentsIds]}
        parentId={parentId}
        isRoot={isRoot}
        index={0}
      />
      {
        todosOrder.map((id: TodoModel['id'], index: number) => {
          const todo = todos[id];
          if (!todo) {
            console.error(`Cache out of sync. Project doesn't contain todo ${id}`);
            return null;
          }
          const nextId = todosOrder[index + 1];
          const next: TodoModel | undefined = todos[nextId];
          return (
            <motion.div key={`${id}`} layout transition={spring}>
              <TodoItem
                todo={todo}
                todos={todos}
                nestingLevel={nestingLevel}
                parentId={parentId}
                allParentsIds={[...allParentsIds, parentId]}
                index={index}
              />
              <TodoEdge
                type={DnDMoveType.AFTER}
                todo={todo}
                isRoot={isRoot}
                disabledIds={next ? [next.id, ...allParentsIds] : allParentsIds}
                parentId={parentId}
                index={index}
              />
            </motion.div>
          );
        })
      }
    </List>
  );
};
