import React from 'react';
import AccountCircleTwoTone from '@material-ui/icons/AccountCircleTwoTone';
import { RegisterFormModel } from '../../../models';
import { RegisterForm } from './RegisterForm';
import { AuthCard } from '../AuthCard';
import { Paths } from '../../../paths';

interface RegisterCardProps {
  onSignUp(formValues: RegisterFormModel): Promise<any>;
}

export const RegisterCard: React.FC<RegisterCardProps> = ({ onSignUp }) => (
  <AuthCard
    onSubmit={onSignUp}
    formComponent={RegisterForm}
    title="Create an account"
    submitText="Sign up"
    alternativeAction={{
      targetPath: Paths.LOGIN,
      actionText: 'Sign in',
    }}
    iconComponent={AccountCircleTwoTone}
  />
);
