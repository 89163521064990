import * as React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
// TODO Update @types/react-dnd-multi-backend when it supports ^11.1.3
import { DndProvider, MouseTransition, TouchTransition } from 'react-dnd-multi-backend';

export const options = {
  backends: [
    {
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      backend: TouchBackend,
      preview: true,
      transition: TouchTransition,
      options: { delayTouchStart: 250 },
      // enableMouseEvents: true,
      // delayMouseStart: 250,
    },
  ],
};


export const MultiDndProvider: React.FC = React.memo(
  function MultiDndProvider({ children }) {
    return (
      <DndProvider options={options as any}>
        {children}
      </DndProvider>
    );
  },
);
