import { gql, useLazyQuery } from '@apollo/client';
import { NotificationType, projectFragment, ProjectModel } from '../../models';
import { useEffect, useRef } from 'react';
import { useNotifications } from '../../core/message-bar';

const getProjectQuery = gql`
  query GetProject($id: uuid!) {
    project: project_by_pk(id: $id) {
      ...ProjectModel
    }
  }
  
  ${projectFragment}
`;

interface QueryResult {
  project: ProjectModel;
}

export const useGetProject = (id: string | undefined) => {
  const { display } = useNotifications();
  const [getProject, result] = useLazyQuery<QueryResult>(getProjectQuery);
  const lastIDRef = useRef<string | undefined>(undefined);
  const { error } = result;

  useEffect(() => {
    if (error) {
      console.error(error);
      display({
        type: NotificationType.ERROR,
        message: 'Failed to get the project',
      });
    }
  }, [error, display]);

  if (!id) {
    return result;
  }

  if (id !== lastIDRef.current) {
    getProject({ variables: { id } });
    lastIDRef.current = id;
  }

  return result;
};
