import { RedirectUser } from '../RedirectUser';
import React from 'react';
import { ForgotPasswordFormModel } from '../../../models';
import { SingleColumn } from '../../PageLayout';
import { ForgotPasswordCard } from './ForgotPasswordCard';
import { Header } from '../../../layout/components/Header';
import { SignInButton } from '../SignInButton';
import { SignUpButton } from '../SignUpButton';

export const ForgotPasswordView: React.FC = () => {
  const requestCode = (formValues: ForgotPasswordFormModel) => {
    return Promise.resolve();
  };

  const header = (
    <Header title="NoTodos">
      <SignInButton />
      <SignUpButton />
    </Header>
  );

  return (
    <RedirectUser to="/todos">
      <SingleColumn header={header}>
        <ForgotPasswordCard onRequestCode={requestCode} />
      </SingleColumn>
    </RedirectUser>
  );
};
