import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(5),
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '25vw',
      maxWidth: '320px',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0),
        boxSizing: 'border-box',
        maxWidth: '100%',
        width: '100vw',
        height: '100%',
      },
    },
    actionBtn: {
      marginLeft: 'auto',
    },
    info: {
      display: 'block',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      whiteSpace: 'pre-wrap',
    },
    cardFooter: {
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
);
