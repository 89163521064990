import React from 'react';
import { makeStyles } from '@material-ui/styles';
import logo from '../images/logo.png';
import { Theme } from '@material-ui/core/index';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme: Theme) => ({
  screen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    textAlign: 'center',
  },
  brand: {
    width: 128,
    height: 128,
    margin: theme.spacing(2),
  },
}));

export const SplashScreen: React.FC = React.memo(function SplashScreen() {
  const classes = useStyles();
  return (
    <div className={classes.screen}>
      <div>
        <img src={logo} alt="NoTodos Logo" className={classes.brand} />
        <LinearProgress />
      </div>
    </div>
  );
});
