import React, { useCallback } from 'react';
import { Overlay, useOverlayToggle } from '../../hooks/overlay.hook';
import Add from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import { View } from '../PageLayout/layout-manager.hook';
import { useLayout } from '../PageLayout/layout.hook';
import { FAB } from '../FAB';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    zIndex: 1,
    bottom: 30,
    left: '50%',
    transform: 'translateX(-50%)',
    // right: 0,
    // margin: '0 auto',
  },
}));

export const CreateTodoFAB: React.FC = React.memo(
  function CreateTodoFAB() {
    const classes = useStyles();
    const [, toggleTodoOverlay] = useOverlayToggle(Overlay.CREATE_TODO_OVERLAY);
    const { setCurrentView } = useLayout();
    const handleClick = useCallback(() => {
      setCurrentView(View.CREATE_TODO);
      toggleTodoOverlay();
    }, [setCurrentView, toggleTodoOverlay]);
    return (
      <div className={classes.root}>
        <FAB
          color="primary"
          label="Create todo"
          onClick={handleClick}
          wide
        >
          <Add />
        </FAB>
      </div>
    );
  },
);
