import React from 'react';
import { motion } from 'framer-motion';
import ExpandMore from '@material-ui/icons/ExpandMore';

export interface ExpandIconProps {
  open: boolean;
  reverse?: boolean;
}

const reverseVariants = {
  open: { transform: 'rotate(360deg)' },
  close: { transform: 'rotate(180deg)' },
};

const variants = {
  open: { transform: 'rotate(180deg)' },
  close: { transform: 'rotate(360deg)' },
};

export const ExpandIcon: React.FC<ExpandIconProps> = ({ open, reverse = false }) => (
  <motion.div
    animate={open ? 'open' : 'close'}
    variants={reverse ? reverseVariants : variants}
    transition={{ duration: 0.2 }}
    style={{ width: '24px', height: '24px' }}
  >
    <ExpandMore />
  </motion.div>
);
