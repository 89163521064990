import React, { useContext, useMemo } from 'react';
import { initializeApp } from '@firebase/app';
import { getAuth } from '@firebase/auth';
import { getDatabase } from '@firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyBWwOnpLUti7gwSwHEmpGVg7PuoBZ-sbU8",
  authDomain: "notodos.firebaseapp.com",
  databaseURL: "https://notodos.firebaseio.com",
  projectId: "notodos",
  storageBucket: "notodos.appspot.com",
  messagingSenderId: "998530336234",
  appId: "1:998530336234:web:7074afbd1829888114de93",
  persistence: true,
};

export class Firebase {
  app: ReturnType<typeof initializeApp>;
  auth: ReturnType<typeof getAuth>;
  database: ReturnType<typeof getDatabase>;

  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth(this.app);
    this.database = getDatabase(this.app);
  }
}

const FirebaseContext = React.createContext<Firebase | undefined>(undefined);

export const useFirebase = (): Firebase => {
  const firebase = useContext(FirebaseContext);
  if (!firebase) {
    throw new Error('The `useFirebase` hook must be used within `FirebaseProvider` scope.');
  }
  return firebase;
};

export const FirebaseProvider: React.FC = React.memo(
  function FirebaseProvider({ children }) {
    const firebase = useMemo(() => new Firebase(), []);
    return (
      <FirebaseContext.Provider value={firebase}>
        {children}
      </FirebaseContext.Provider>
    );
  }
);

