import React, { ReactElement } from 'react';
import { Toggle } from '../../../Toggle';
import { useField } from 'formik';
import { DateMode } from '../../../../models';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  modePicker: {
    textAlign: 'right',
  },
}));

const options = [
  { value: DateMode.NONE, label: 'Anytime' },
  { value: DateMode.REMINDER, label: 'Reminder' },
  { value: DateMode.EVENT, label: 'Event' },
  { value: DateMode.ALL_DAY, label: 'All day' },
];

export const DateModePicker = (): ReactElement => {
  const classes = useStyles();
  const [{ value: dateMode }, , { setValue: setDateMode }] = useField<DateMode>('dateMode');

  return (
    <div className={classes.modePicker}>
      <Toggle options={options} value={dateMode} onChange={(_, value) => setDateMode(value)} />
    </div>
  );
};
