import React from 'react';
import { Loading } from '../Loading';
import { InsertProjectInputModel } from '../../models/projects';
import { useCreateProject } from '../../hooks/projects/create-project.hook';
import { NoResources } from '../NoResources';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';

const seedInbox: InsertProjectInputModel = {
  title: 'General',
  color: '#04afff',
  hidden: false,
};

export const EmptyProjectList = () => {
  const { loading, insertProject } = useCreateProject();
  const createInbox = () => insertProject(seedInbox);
  return (
    <Loading isLoading={loading}>
      <NoResources
        title="No projects found"
        icon={EmojiObjectsIcon}
        callToAction={{ call: 'Create Inbox', action: createInbox }}
      >
        You can start by creating one
      </NoResources>
    </Loading>
  );
};
