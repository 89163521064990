import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';

interface ProjectIconProps {
  color?: string;
  selected: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0.5, 1),
    display: 'block',
    fill: ({ color }: ProjectIconProps) => color,
  },
}));

export const ProjectIcon: React.FC<ProjectIconProps> = ({ color = '#607d8b', selected }) => {
  const classes = useStyles({ color, selected });
  return (
    <SvgIcon className={classes.root} viewBox="-2 -2 74 74">
      <path
        d='M18 58 L46 58 L62 32 L46 6 L18 6 L2 32 Z'
        strokeWidth={8}
        stroke={color}
        strokeOpacity={1}
        fill={color}
        fillOpacity={selected ? 0.3 : 0}
      />
    </SvgIcon>
  );
};
