import React, { ComponentType, createContext, PropsWithChildren, ReactElement, ReactNode, useContext } from 'react';

interface ViewPickerSwitchProps {
  currentView: string;
}

type ViewPickerCaseProps<Props> = Props & {
  viewId?: string;
  component?: ComponentType<Props>;
  children?: ReactNode;
}

const ViewPickerContext = createContext<ViewPickerSwitchProps | undefined>(undefined);

const Switch: React.FC<ViewPickerSwitchProps> = ({ children, ...context }) => (
  <ViewPickerContext.Provider value={context}>
    {children}
  </ViewPickerContext.Provider>
);

const Case = <Props, >(props: PropsWithChildren<ViewPickerCaseProps<Props>>): ReactElement | null => {
  const {
    viewId,
    component: Component,
    children,
  } = props;
  const matchContext = useContext(ViewPickerContext);
  if (!matchContext) {
    throw new Error('Component `ViewPicker.Case` can only be used inside of `ViewPicker.Switch`.');
  }
  const { currentView } = matchContext;
  if (viewId && currentView !== viewId) {
    return null;
  }
  if (Component) {
    return (
      <Component {...props}>{children}</Component>
    );
  }
  if (typeof children === 'function') {
    return <>{children(currentView)}</>;
  }
  return <>{children}</>;
};

export const ViewPicker = {
  Switch,
  Case,
};
