import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { lightTheme } from './themes/light-theme';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { Routes } from 'components/Routes';
import { AuthProvider } from './core';
import { ApolloClientProvider } from './core/ApolloClientProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { FirebaseProvider } from './core/firebase';
import { MultiDndProvider } from './core/MultiDndProvider';

export const routerHistory = createBrowserHistory();

const App: React.FC = () => (
  <FirebaseProvider>
    <ThemeProvider theme={lightTheme}>
      <AuthProvider>
        <ApolloClientProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MultiDndProvider>
              <Router history={routerHistory}>
                <Routes />
              </Router>
            </MultiDndProvider>
          </MuiPickersUtilsProvider>
        </ApolloClientProvider>
      </AuthProvider>
    </ThemeProvider>
  </FirebaseProvider>
);

export default App;
