import { TodoDragModel } from '../../../models/todos';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { TodoDragPropsModel } from '../../../models/todos';
import { useTodoMove } from './todo-move.hook';

export const useTodoDrag = (item: TodoDragModel) => {
  const handleMoveTodo = useTodoMove();
  return useDrag({
    item,
    collect: (monitor: DragSourceMonitor): TodoDragPropsModel => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dragModel: TodoDragModel | undefined, monitor: DragSourceMonitor): void => {
      if (!monitor.didDrop() || !dragModel) {
        return;
      }
      const dropModel = monitor.getDropResult();
      handleMoveTodo(dragModel, dropModel);
    },
  });
};
