import React from 'react';
import { FormControl, Grid, SvgIcon } from '@material-ui/core';
import { WithClassName } from '../models/types';
import classNames from 'classnames';

export interface FormControlWithIconProps {
  icon: typeof SvgIcon;
  classes?: {
    root?: string;
    formControl?: string;
  }
}

export const FormControlWithIcon: React.FC<WithClassName & FormControlWithIconProps> = ({
  icon: IconComponent,
  children,
  classes,
  className,
}) => (
  <Grid
    container
    wrap="nowrap"
    alignItems="flex-end"
    alignContent="flex-end"
    className={classNames(className, classes?.root)}
  >
    <Grid item>
      <IconComponent color="action" style={{ padding: '0 16px' }}/>
    </Grid>
    <Grid item container>
      <FormControl className={classes?.formControl}>
        {children}
      </FormControl>
    </Grid>
  </Grid>
);
