import React, { useEffect } from 'react';
import { AuthContext, AuthStatus, useSpawnAuthManager } from '../auth.hook';
import { useWaitForClaims } from '../wait-for-claims.hook';
import { DisplayIf } from '../../../components/DisplayIf';
import { SplashScreen } from '../../../components/SplashScreen';
import { useFirebase } from '../../firebase';
import { useConnectionState } from '../../../hooks/connection-state.hook';
import { User } from '@firebase/auth-types';

export const AuthProvider: React.FC = ({ children }) => {
  const { auth } = useFirebase();
  const authManager = useSpawnAuthManager();
  const waitForClaims = useWaitForClaims();
  const isOnline = useConnectionState();
  const { signedIn, signedOut, errored, status } = authManager;

  useEffect(() => {
    async function onAuthStateChangedHandler(user: User | null) {
      if (!user) {
        signedOut();
        return;
      }
      try {
        if (isOnline) {
          await waitForClaims(user);
        }
      } catch (error) {
        console.error(error);
        return;
      }
      try {
        const token = await user.getIdToken();
        signedIn(token, user);
      } catch (error) {
        errored();
        console.error('Failed to obtain user\'s token.');
        console.error(error);
      }
    }
    return auth.onAuthStateChanged(onAuthStateChangedHandler);
  }, [auth, waitForClaims, signedIn, signedOut, errored, isOnline]);
  const loadingSpinner = <SplashScreen />;
  return (
    <AuthContext.Provider value={authManager}>
      <DisplayIf expr={status !== AuthStatus.LOADING} onElse={loadingSpinner}>
        {children}
      </DisplayIf>
    </AuthContext.Provider>
  );
};
