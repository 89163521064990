import * as yup from 'yup';
import { TodoModel } from '../todos';
import { gql } from '@apollo/client';

export interface ProjectModel {
  id: string;
  title: string;
  todos: TodoModel[];
  color: string;
  hidden: boolean;
  collaborators: string[];
  owner: string;
  todos_order: string[];
  is_selected: boolean;
  // isPinned: boolean;
  // createdOn: number;
  // deadlineOn?: number;
}

export const projectFragment = gql`
  fragment ProjectModel on project {
    id
    title
    color
    hidden
    owner
    todos_order
    is_selected @client
  }
`;

export const projectSchema = yup.object().shape({
  title: yup.string()
    .required('Project title has to be provided'),
  color: yup.string()
    .required('Project icon color is required'),
});
