import React from 'react'
import { ProjectModel } from '../../models';
import { ProjectIcon } from './ProjectIcon';
import { SidebarItem } from '../Sidebar/SidebarItem';
import { ProjectOpts } from './ProjectOpts';

interface ProjectListItemProps {
  isManaging: boolean;
  isSelected: boolean;
  project: ProjectModel;
  onSelect(nextId: string): void;
}

export const ProjectListItem: React.FC<ProjectListItemProps> = ({ project, isManaging, onSelect, isSelected }) => {
  const { title, color, id } = project;
  const selectProject = () => onSelect(project.id);
  return (
    <SidebarItem
      icon={<ProjectIcon color={color} selected={isSelected} />}
      title={title}
      onClick={selectProject}
      selected={isSelected}
    >
      {isManaging && <ProjectOpts title={title} id={id} />}
    </SidebarItem>
  );
};
