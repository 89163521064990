import { gql, useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';

export enum Overlay {
  UNSET = 'unset',
  CREATE_TODO_OVERLAY = 'create-todo-overlay',
}

type SetOverlay = (next: Overlay) => void;
type ToggleOverlay = () => void;

export const currentOverlayQuery = gql`
  {
    currentOverlay @client
  }
`;

interface QueryData {
  currentOverlay: Overlay;
}

const initialState = {
  currentOverlay: Overlay.UNSET,
};


export const useOverlay = (): [Overlay, SetOverlay] => {
  const client = useApolloClient();
  const { data: { currentOverlay } = initialState } = useQuery<QueryData>(currentOverlayQuery);
  const setCurrentOverlay = useCallback((currentOverlay: Overlay) => {
    client.writeQuery({
      query: currentOverlayQuery,
      data: { currentOverlay },
    });
  }, [client]);

  return [currentOverlay, setCurrentOverlay];
};

export const useOverlayToggle = (id: Overlay): [boolean, ToggleOverlay] => {
  const [currentOverlay, setOverlay] = useOverlay();
  const isCurrent = currentOverlay === id;
  const toggleOverlay = useCallback(() => {
    setOverlay(isCurrent ? Overlay.UNSET : id);
  }, [isCurrent, setOverlay, id]);
  return [isCurrent, toggleOverlay];
}
