import React from 'react';
import { Route, Switch } from 'react-router';
import { Paths } from '../paths';
import { ActivationView, ForgotPasswordView, LoginView, RegisterView, ResetPasswordView } from './AuthView';
import { enableSignUp } from '../feature-flags';
import { TodosView } from './TodosView';
import { HomeView } from './HomeView';
import { PrivateRoute } from '../core/auth/components';
import { PublicRoutesGroup } from '../core/auth/components/PublicRoutesGroup';

export const Routes: React.FC = () => (
  <Switch>
    <PrivateRoute path={Paths.TODOS} exact>
      <TodosView />
    </PrivateRoute>
    <PublicRoutesGroup onSignedInPath={Paths.TODOS}>
      <Switch>
        <Route path={Paths.LOGIN} exact component={LoginView} />
        {enableSignUp && <Route path={Paths.REGISTER} exact component={RegisterView} />}
        <Route path={Paths.ACTIVATE_ACCOUNT} exact component={ActivationView} />
        <Route path={Paths.FORGOT_PASSWORD} exact component={ForgotPasswordView} />
        <Route path={Paths.RESET_PASSWORD} exact component={ResetPasswordView} />
        <Route component={HomeView} />
      </Switch>
    </PublicRoutesGroup>
  </Switch>
);
