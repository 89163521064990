import React from 'react';
import { ProjectList } from '../ProjectList';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Button, Drawer, List, ListItem, ListItemText, Theme } from '@material-ui/core';
import { ToolbarSpan } from './ToolbarSpan';
import { Railbar } from './Railbar';
import AddIcon from '../../images/icon-add.png';
import { useLayout } from '../PageLayout';
import { selectedProjectVar } from '../../core/apollo';

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth + 80,
      height: '100%',
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      paddingLeft: 80,
      color: theme.palette.text.secondary,
    },
    toolbar: {
      ...theme.mixins.toolbar
    },
    fab: {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(2.5),
    },
  }),
);

export const Sidebar: React.FC = () => {
  const classes = useStyles();
  const { toggleSideSheet } = useLayout();
  const selectedProject = selectedProjectVar();

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={true}
      classes={{
        paper: classes.drawerPaper
      }}
      elevation={3}
    >
      <ToolbarSpan />
      <Railbar />
      <List dense>
        <ListItem>
          <ListItemText>
            <Button
              variant="contained"
              className={classes.fab}
              startIcon={<img src={AddIcon} alt="Create todo" />}
              onClick={() => toggleSideSheet()}
              disabled={!selectedProject}
            >
              Create todo
            </Button>
          </ListItemText>
        </ListItem>
      </List>
      <ProjectList />
    </Drawer>
  );
};
