import { gql, useMutation } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import { NotificationType, ProjectModel, TodoModel } from '../../models';
import { BatchDoneInputModel } from '../../models/todos/batch-done-input.model';
import { useNotifications } from '../../core/message-bar';
import { MutationUpdaterFn } from '@apollo/client/core';

const batchDoneMutation = gql`
  mutation BatchDone(
    $ids: [uuid!]!,
    $isDone: Boolean!
  ) {
    update_todo(
      where: {id: {_in: $ids}},
      _set: {isDone: $isDone}
    ) {
      affected_rows
      returning {
        __typename
        id
        isDone
      }
    }
  }
`;

interface BatchDoneResult {
  update_todo: {
    __typename?: 'todo_mutation_response';
    affected_rows: number;
    returning: Array<{ id: TodoModel['id']; isDone: boolean; __typename: 'todo' }>;
  };
}

const onUpdate: MutationUpdaterFn<BatchDoneResult> = (cache, { data }) => {
  if (!data) return;
  const { update_todo: { returning } } = data;
  returning.forEach((change) => {
    cache.modify({
      id: cache.identify(change),
      fields: {
        isDone: () => change.isDone,
      },
    });
  });
};

// TODO Use type policies to match this pattern
export const useBatchDone = () => {
  const [batchTodosDone, result] = useMutation<BatchDoneResult, BatchDoneInputModel>(batchDoneMutation);
  const { display } = useNotifications();
  const { error: { message: errMsg } = {} } = result;

  const onFailure = useCallback((error) => {
    console.error(error);
    display({
      type: NotificationType.ERROR,
      message: 'Failed to mark todos as (un)done',
    });
  }, [display]);

  const performMutation = useCallback(
    function performMutation(projectId: ProjectModel['id'], variables: BatchDoneInputModel) {
      const { ids, isDone } = variables;
      const optimisticResponse = {
        update_todo: {
          __typename: 'todo_mutation_response' as const,
          affected_rows: ids.length,
          returning: ids.map(id => ({ id, isDone, __typename: 'todo' as const })),
        },
      };
      return batchTodosDone({
        variables,
        optimisticResponse,
        update: onUpdate,
      })
        .catch(onFailure);
    }, [batchTodosDone, onFailure]);

  useEffect(() => {
    errMsg && onFailure(errMsg);
  }, [onFailure, errMsg]);

  return useMemo(() => ({
    batchTodosDone: performMutation,
    ...result,
  }), [performMutation, result]);
};
