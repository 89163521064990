import React, { MouseEvent } from 'react'
import { ListItem, ListItemIcon, Typography } from '@material-ui/core';

interface SidebarItemProps {
  icon: React.ReactElement;
  title: string;
  onClick: (evt: MouseEvent<HTMLDivElement>) => void;
  selected: boolean;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({ icon, title, onClick, selected, children }) => (
  <ListItem onClick={onClick} button={true} selected={selected}>
    <ListItemIcon>
      {icon}
    </ListItemIcon>
    <Typography variant="subtitle2">{title}</Typography>
    {children}
  </ListItem>
);
